import React, { useContext } from "react";
import styles from "./StoreAccordionFilter.module.css";
// Components
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Loader from "../../../../Components/Loader/Loader";
// Context
import { LanguageContext } from "../../../../Context/LanguageContext";
// Hooks
import { useQuery } from "react-query";
// Pages
// Resources
import { ApiInterface } from "../../../../resources/api/Api";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { FormGroup } from "react-bootstrap";
import { Checkbox } from "@material-ui/core";

interface Props {
  categories: Array<string>;
  setCategories: (state: Array<string>) => void;
}

function StoreFilterByCategory({ categories, setCategories }: Props) {
  const { language } = useContext(LanguageContext);
  const { data, isFetching } = useQuery(
    ["categories", language],
    () => ApiInterface().store.getCategories(language),
    { retry: 10, retryDelay: 500 }
  );

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    if (
      Boolean(
        categories.find((a) => a === (event.target as HTMLInputElement).value)
      )
    ) {
      let temp = categories.filter((prevState) => {
        return prevState !== (event.target as HTMLInputElement).value;
      });
      setCategories(temp);
    } else {
      setCategories(
        categories.concat((event.target as HTMLInputElement).value)
      );
    }
  }

  return (
    <Accordion className={styles["accordion"]}>
      <AccordionSummary
        className={styles["accordion__summary"]}
        expandIcon={<ExpandMoreIcon />}
      >
        {{ pt: "Categorias", en: "Categories" }[language]}
      </AccordionSummary>
      <AccordionDetails className={styles["accordion__details"]}>
        <FormControl component="fieldset">
          <FormGroup className={styles["form-group"]}>
            {isFetching ? (
              <Loader />
            ) : data ? (
              data.data.data.node.children.elements.map((category, index) => {
                return (
                  <FormControlLabel
                    key={index}
                    value={category.displayName}
                    control={<Checkbox color="default" onClick={handleClick} />}
                    label={category.displayName}
                  />
                );
              })
            ) : null}
          </FormGroup>
        </FormControl>
      </AccordionDetails>
    </Accordion>
  );
}

export default StoreFilterByCategory;
