import { ISLANDS } from "../../resources/typing/enumerates";

const contacts = {
  addresses: [
    {
      location: "Nonagon - Ponta Delgada",
      address: "Rua engº José Cordeiro, Nº6 9504-535 Ponta Delgada",
      phone: "296 202 525",
      email: "geral@globaleda.pt",
      googleMaps: "https://www.google.com",
      island: ISLANDS.SAOMIGUEL,
    },
    {
      location: "Nonagon - Ponta Delgada",
      address: "Rua engº José Cordeiro, Nº6 9504-535 Ponta Delgada",
      phone: "296 202 525",
      email: "geral@globaleda.pt",
      googleMaps: "https://www.google.com",
      island: ISLANDS.PICO,
    },
    {
      location: "Nonagon - Ponta Delgada",
      address: "Rua engº José Cordeiro, Nº6 9504-535 Ponta Delgada",
      phone: "296 202 525",
      email: "geral@globaleda.pt",
      googleMaps: "https://www.google.com",
      island: ISLANDS.TERCEIRA,
    },
    {
      location: "Nonagon - Ponta Delgada",
      address: "Rua engº José Cordeiro, Nº6 9504-535 Ponta Delgada",
      phone: "296 202 525",
      email: "geral@globaleda.pt",
      googleMaps: "https://www.google.com",
      island: ISLANDS.TERCEIRA,
    },
    {
      location: "Nonagon - Ponta Delgada",
      address: "Rua engº José Cordeiro, Nº6 9504-535 Ponta Delgada",
      phone: "296 202 525",
      email: "geral@globaleda.pt",
      googleMaps: "https://www.google.com",
      island: ISLANDS.SAOMIGUEL,
    },
    {
      location: "Nonagon - Ponta Delgada",
      address: "Rua engº José Cordeiro, Nº6 9504-535 Ponta Delgada",
      phone: "296 202 525",
      email: "geral@globaleda.pt",
      googleMaps: "https://www.google.com",
      island: ISLANDS.SAOMIGUEL,
    },
  ],
  title: {
    pt: "Informação Geral",
    en: "General Information",
  },
  buttonText: {
    pt: "Entrar em contato",
    en: "Get in touch",
  },
  menuOptions: {
    pt: ["Escritórios", "Lojas Globaleda", "Lojas Vodafone"],
    en: ["Offices", "Globaleda Shops", "Vodafone Shops"],
  },
};

export { contacts };
