import axios from "axios";
import { HomepageAPI } from "./Homepage/Homepage";
import { StoreAPI } from "./Store/Store";
import { PortfolioAPI } from "./Portfolio/Portfolio";
import { PartnerAPI } from "./Partnerships/Partnerships";
import { DocumentsApi } from "./Documents/Documents";
import { ContactsApi } from "./Contacts/Contacts";
import { AboutApi } from "./About/About";
import { OffersAPI } from "./Offers/Offers";
import { SearchApi } from "./Search/Search";

export const APP_BACK_OFFICE_URL =
  "/api/v2/portal-globaleda/";

export const backOfficeAPI = {
  axios: axios.create({
    baseURL: APP_BACK_OFFICE_URL,
    headers: {
      "content-Type": " application/json",
      accept: "application/json",
    },
  }),
};

export const ApiInterface = () => ({
  homepage: HomepageAPI(),
  store: StoreAPI(),
  portfolio: PortfolioAPI(),
  partner: PartnerAPI(),
  documents: DocumentsApi(),
  contacts: ContactsApi(),
  about: AboutApi(),
  offers: OffersAPI(),
  search: SearchApi()
});
