import React, { useContext } from "react";
import styles from "./SuccessPopupContent.module.css";
// Components
import Button from "../../Button/Button";
// Context
// Hooks
// Pages
// Resources
import PopupInformation from "../PopupInformation.json";
import { LanguageContext } from "../../../Context/LanguageContext";

interface Props {
  closePopup: () => void;
}

export default function SuccessPopupContent({ closePopup }: Props) {
  const { language } = useContext(LanguageContext);

  return (
    <div className={styles["success-popup"]}>
      <h1>{PopupInformation.sentTitle[language]}</h1>
      <p>{PopupInformation.sentSubtitle[language]}</p>
      <Button
        text={PopupInformation.returnButton[language]}
        showArrow={false}
        color="button--blue"
        width="8em"
        alignSelf="center"
        onClick={closePopup}
      />
    </div>
  );
}
