import { AxiosResponse } from "axios";
import { backOfficeAPI } from "../Api";
import {
  ProductResponses,
  StoreResponses,
  BrandsResponses,
  CategoriesResponses,
} from "./Types";

export const StoreAPI = () => ({
  getProducts: async (
    lang: string,
    searchBarInput: string,
    brands: Array<string>,
    categories: Array<string>
  ): Promise<AxiosResponse<StoreResponses.RootObject>> =>
    new Promise((resolve, reject) => {
      let possibleBrands = "";
      brands.map((brand, index) => {
        if (index !== 0) {
          return (possibleBrands += `|${brand}`);
        } else {
          return (possibleBrands += `${brand}`);
        }
      });

      let possibleCategories = "";
      categories.map((category, index) => {
        if (index !== 0) {
          return (possibleCategories += `|${category}`);
        } else {
          return (possibleCategories += `${category}`);
        }
      });

      backOfficeAPI.axios
        .post("/graphql", {
          query: `{
            node(path: "/loja") {
              children(filter: {schema: {is: folder}, fields: {folder: {name: {regex: "^(${possibleCategories})"}}}}, lang: "${lang}") {
                elements{
                  children(version: published, filter: {schema: {is: product}, fields: {product: {name: {regex: "${searchBarInput}"}, brand: {regex: "^(${possibleBrands})"}}}}) {
                    elements {
                      uuid
                      created
                      ... on product {
                        fields {
                          name
                          prevPrice
                          price
                        }
                      }
                    }
                  }
                }
              }
            }
          }`,
        })
        .then((response: AxiosResponse<StoreResponses.RootObject>) => {
          resolve(response);
        });
    }),
  getProduct: async (
    uuid: string,
    lang: string
  ): Promise<AxiosResponse<ProductResponses.RootObject>> =>
    new Promise((resolve, reject) => {
      backOfficeAPI.axios
        .post("/graphql", {
          query: `{
          node(uuid: "${uuid}", lang: "${lang}") {
            uuid
            ... on product {
              fields {
                brand
                name
                amount
                description
                prevPrice
                price
                warranty
                backCamera
                color
                Dimensions
                frontalCamera
                memory
                model
                operatingSystem
                processor
                resolution
                size
                technology
                weight
                 images {
                  uuid
                }
              }
            }
          }
        }`,
        })
        .then((response: AxiosResponse<ProductResponses.RootObject>) => {
          resolve(response);
        });
    }),
  getBrands: async (): Promise<AxiosResponse<BrandsResponses.RootObject>> =>
    new Promise((resolve, reject) => {
      backOfficeAPI.axios
        .post("/graphql", {
          query: `{
          nodes(filter: {schema: {is: brand}}) {
            elements {
              ... on brand {
                fields {
                  name
                }
              }
            }
          }
        }`,
        })
        .then((response: AxiosResponse<BrandsResponses.RootObject>) => {
          resolve(response);
        });
    }),
  getCategories: async (
    lang: string
  ): Promise<AxiosResponse<CategoriesResponses.RootObject>> =>
    new Promise((resolve, reject) => {
      backOfficeAPI.axios
        .post("/graphql", {
          query: `{
          node(path: "/loja") {
            children(filter: {schema: {is: folder}}, lang: "${lang}") {
              elements {
                displayName
              }
            }
          }
        }`,
        })
        .then((response: AxiosResponse<CategoriesResponses.RootObject>) => {
          resolve(response);
        });
    }),
});
