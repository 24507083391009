import { useContext } from "react";
import { LanguageContext } from "../../../../Context/LanguageContext";
import { ProductResponses } from "../../../../resources/api/Store/Types";
import styles from "./StoreItemInfo.module.css";

interface Props {
  product: ProductResponses.Data;
}

export default function StoreItemInfo({ product }: Props) {
  const { language } = useContext(LanguageContext);
  return (
    <div className={styles["product__detailed-info"]}>
      <table className={styles["product__general-info"]}>
        <tbody>
          <tr>
            <th>{{ pt: "Geral", en: "General" }[language]}</th>
          </tr>
          <tr>
            <td>{{ pt: "Modelo", en: "Model" }[language]}</td>
            <td>{product.node.fields.model}</td>
          </tr>
          <tr>
            <td>{{ pt: "Cor", en: "Color" }[language]}</td>
            <td>{product.node.fields.color}</td>
          </tr>
          <tr>
            <td>{{ pt: "Marca", en: "Brand" }[language]}</td>
            <td>{product.node.fields.brand}</td>
          </tr>
        </tbody>
      </table>

      <table className={styles["product__screen-info"]}>
        <tbody>
          <tr>
            <th>
              {{ pt: "Visor e Imagem", en: "Viewfinder and Image" }[language]}
            </th>
          </tr>
          <tr>
            <td>
              {
                {
                  pt: "Câmera Frontal (Megapixel)",
                  en: "Front Camera (Megapixel)",
                }[language]
              }
            </td>
            <td>{product.node.fields.frontalCamera}</td>
          </tr>
          <tr>
            <td>
              {
                {
                  pt: "Câmera Traseira (Megapixel)",
                  en: "Back Camera (Megapixel)",
                }[language]
              }
            </td>
            <td>{product.node.fields.backCamera}</td>
          </tr>
          <tr>
            <td>
              {
                {
                  pt: "Ecrã - resolução (horizXvert) (pixel)",
                  en: "Screen - resolution (horizXvert) (pixel)",
                }[language]
              }
            </td>
            <td>{product.node.fields.resolution}</td>
          </tr>
          <tr>
            <td>
              {
                {
                  pt: "Ecrã - tamanho (polegadas)",
                  en: "Screen - size (inches)",
                }[language]
              }
            </td>
            <td>{product.node.fields.size}</td>
          </tr>
        </tbody>
      </table>

      <table className={styles["product__connectivity-info"]}>
        <tbody>
          <tr>
            <th>
              {
                {
                  pt: "Conectividade e Sistemas",
                  en: "Connectivity and Systems",
                }[language]
              }
            </th>
          </tr>
          <tr>
            <td>
              {{ pt: "Sistema Operativo", en: "Operating System" }[language]}
            </td>
            <td>{product.node.fields.operatingSystem}</td>
          </tr>
          <tr>
            <td>{{ pt: "Memória", en: "Memory" }[language]}</td>
            <td>{product.node.fields.memory}</td>
          </tr>
          <tr>
            <td>{{ pt: "Tecnologia", en: "Technology" }[language]}</td>
            <td>{product.node.fields.technology}</td>
          </tr>
          <tr>
            <td>{{ pt: "Processador", en: "Processor" }[language]}</td>
            <td>{product.node.fields.processor}</td>
          </tr>
        </tbody>
      </table>

      <table className={styles["product__physical-info"]}>
        <tbody>
          <tr>
            <th>
              {
                { pt: "Especificações Físicas", en: "Physical Specifications" }[
                  language
                ]
              }
            </th>
          </tr>
          <tr>
            <td>
              {
                {
                  pt: "Dimensão (comp x larg x esp) (mm)",
                  en: "Dimension (length x width x width) (mm)",
                }[language]
              }
            </td>
            <td>{product.node.fields.Dimensions}</td>
          </tr>
          <tr>
            <td>{{ pt: "Peso(g)", en: "Weight(g)" }[language]}</td>
            <td>{product.node.fields.weight}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
