import React, { useContext } from "react";
import styles from "./StoreSearchBar.module.css";
// Components
// Context
// Hooks
// Pages
// Resources
import { LanguageContext } from "../../../../Context/LanguageContext";
import search_icon from "../../../../resources/media/icons/header/search.svg";

interface Props {
  searchBarInput: string;
  setSearchBarInput: (input: string) => void;
}

function StoreSearchBar({ searchBarInput, setSearchBarInput }: Props) {
  const { language } = useContext(LanguageContext);

  return (
    <div className={styles["search-bar"]}>
      <input
        type="text"
        className={styles["search-bar__input-line"]}
        value={searchBarInput}
        onChange={(e) => setSearchBarInput(e.target.value)}
        placeholder={
          { pt: "Pesquisar um produto", en: "Search for a product" }[language]
        }
      />
      <img
        src={search_icon}
        width="1.5em"
        height="100%"
        alt="search icon"
        className={styles["search-bar__search-icon"]}
      />
    </div>
  );
}

export default StoreSearchBar;
