import styles from "./About.module.css";
import { Chrono } from "react-chrono";
import CountUp from "react-countup";
// Context
import { LanguageContext } from "../../Context/LanguageContext";
// Hooks
import { useContext, useEffect } from "react";
import useWindowsSize from "../../Hooks/useWindowsSize";
import { useQuery } from "react-query";

//Resources
import logoING from "../../resources/media/images/aboutUs/logo_globaleda_ING.svg";
import logoPT from "../../resources/media/images/aboutUs/logo_globaleda_PT.svg";
import waves from "../../resources/media/images/aboutUs/waves-06.svg";
import { ApiInterface, APP_BACK_OFFICE_URL } from "../../resources/api/Api";
import Loader from "../../Components/Loader/Loader";

import "./About2.css";

export default function AboutUs() {
  const windowsSize = useWindowsSize();
  const { language } = useContext(LanguageContext);
  const { data, isFetching } = useQuery(["aboutCards", language], () =>
    ApiInterface().about.getAboutCards(language)
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (isFetching) {
    return <Loader />;
  }

  if (!data) {
    return null;
  }

  function scrollEnd() {
    console.log("end");
    window.scrollTo(0, document.body.scrollHeight);
  }

  return (
    <div className={styles["timeline"]}>
      <h1>
        {
          {
            pt: "A história por trás do nome Globaleda",
            en: "The story behind the name Globaleda",
          }[language]
        }
      </h1>
      <div className={styles["timeline__chrono-container"]}>
        <Chrono
          mode={windowsSize.width > 575 ? "VERTICAL_ALTERNATING" : "VERTICAL"}
          theme={{
            primary: "#005b9d",
            secondary: "white",
            cardBgColor: "none",
            cardForeColor: "#005b9d",
          }}
          slideShow
          allowDynamicUpdate={true}
          onScrollEnd={() => scrollEnd()}
          slideItemDuration={7000}
        >
          {data.data.data.node.children.elements
            .sort((a, b) => a.fields.index - b.fields.index)
            .map((item, index: number) => {
              if (
                item.fields.title === "Sempre a somar" ||
                item.fields.title === "Always adding"
              ) {
                return (
                  <div key={index} className={styles["timeline__card"]}>
                    <div>
                      {item.fields.image && (
                        <img
                          src={`${APP_BACK_OFFICE_URL}nodes/${item.uuid}/binary/image`}
                          alt=""
                        ></img>
                      )}
                      <h2>{item.fields.title}</h2>
                      <p>{item.fields.text}</p>
                      {item.fields.alwaysAdding &&
                        item.fields.alwaysAdding.map((item, index: number) => {
                          let temp = item.split(" ");
                          return (
                            <h3 key={index}>
                              +
                              <CountUp
                                start={0}
                                end={parseInt(temp[0])}
                                duration={9}
                              />{" "}
                              {item.substr(item.indexOf(" ") + 1)}
                            </h3>
                          );
                        })}
                    </div>
                  </div>
                );
              }
              return (
                <div key={index} className={styles["timeline__card"]}>
                  {item.fields.image && (
                    <img
                      src={`${APP_BACK_OFFICE_URL}nodes/${item.uuid}/binary/image`}
                      alt=""
                    ></img>
                  )}
                  <h2>{item.fields.title}</h2>
                  <div dangerouslySetInnerHTML={{ __html: item.fields.text }} />
                </div>
              );
            })}
        </Chrono>
      </div>
      <img
        src={language === "en" ? logoING : logoPT}
        className={styles["timeline__logo"]}
        alt=""
      />
      <img src={waves} className={styles["timeline__waves"]} alt="waves" />
      <h3 className={styles["timeline__eda"]}>GRUPO EDA</h3>
    </div>
  );
}
