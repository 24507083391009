import styles from "./Store.module.css";
import { Link } from "react-router-dom";
// Components
import StoreGridItem from "./StoreGridItem/StoreGridItem";
import Loader from "../../Components/Loader/Loader";
import StoreSearchBar from "./StoreFilter/StoreSearchBar/StoreSearchBar";
import StoreOrderByFilter from "./StoreFilter/StoreAccordionFilter/StoreOrderByFilter";
import StoreFilterByBrand from "./StoreFilter/StoreAccordionFilter/StoreFilterByBrand";
import StoreFilterByCategory from "./StoreFilter/StoreAccordionFilter/StoreFilterByCategory";
// Context
import { LanguageContext } from "../../Context/LanguageContext";
// Hooks
import { useQuery } from "react-query";
import { useContext, useEffect, useState } from "react";
// Pages
// Resources
import { ApiInterface } from "../../resources/api/Api";

function Store() {
  const { language } = useContext(LanguageContext);
  const [searchBarInput, setSearchBarInput] = useState<string>("");
  const [brands, setBrands] = useState<Array<string>>([]);
  const [categories, setCategories] = useState<Array<string>>([]);
  const [orderBy, setOrderBy] = useState<{ pt: string; en: string }>({
    pt: "Preço decrescente",
    en: "Descending Price",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { data, isFetching } = useQuery(
    ["store", language, searchBarInput, brands, categories],
    () =>
      ApiInterface().store.getProducts(
        language,
        searchBarInput,
        brands,
        categories
      ),
    {
      retry: 10,
      retryDelay: 500,
    }
  );

  return (
    <div className={isFetching ? styles["store-fetching"] : styles["store"]}>
      <div className={styles["store__filter"]}>
        <StoreSearchBar
          searchBarInput={searchBarInput}
          setSearchBarInput={setSearchBarInput}
        />
        <StoreOrderByFilter orderByOption={orderBy} setOrderBy={setOrderBy} />
        <StoreFilterByBrand setBrands={setBrands} brands={brands} />
        <StoreFilterByCategory
          setCategories={setCategories}
          categories={categories}
        />
      </div>
      {isFetching ? (
        <Loader />
      ) : !data ? null : (
        <div className={styles["store__items-grid"]}>
          {data!.data.data.node.children.elements.map((folder) => {
            return folder.children.elements.map((product, index) => {
              return (
                <Link key={index} to={`/loja/${product.uuid}`}>
                  <StoreGridItem product={product} />
                </Link>
              );
            });
          })}
        </div>
      )}
    </div>
  );
}

export default Store;
