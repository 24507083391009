import React, { useContext } from "react";
import styles from "./StoreAccordionFilter.module.css";
// Components
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
// Context
import { LanguageContext } from "../../../../Context/LanguageContext";
// Hooks
// Pages
// Resources
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

interface Props {
  orderByOption: { pt: string; en: string };
  setOrderBy: (option: { pt: string; en: string }) => void;
}

function StoreOrderByFilter({ orderByOption, setOrderBy }: Props) {
  const { language } = useContext(LanguageContext);

  return (
    <Accordion className={styles["accordion"]}>
      <AccordionSummary
        className={styles["accordion__summary"]}
        expandIcon={<ExpandMoreIcon />}
      >
        {{ pt: "Ordenar Por", en: "Order By" }[language]}
      </AccordionSummary>
      <AccordionDetails className={styles["accordion__details"]}>
        <FormControl component="fieldset">
          <RadioGroup name="order by" value={orderByOption[language]}>
            <FormControlLabel
              value={
                { pt: "Preço decrescente", en: "Descending Price" }[language]
              }
              control={<Radio color="default" />}
              label={
                { pt: "Preço decrescente", en: "Descending Price" }[language]
              }
              onClick={() =>
                setOrderBy({ pt: "Preço decrescente", en: "Descending Price" })
              }
            />
            <FormControlLabel
              value={
                { pt: "Preço ascendente", en: "Ascending Price" }[language]
              }
              control={<Radio color="default" />}
              label={
                { pt: "Preço ascendente", en: "Ascending Price" }[language]
              }
              onClick={() =>
                setOrderBy({ pt: "Preço ascendente", en: "Ascending Price" })
              }
            />
            <FormControlLabel
              value={{ pt: "Mais recente", en: "Most Recent" }[language]}
              control={<Radio color="default" />}
              label={{ pt: "Mais recente", en: "Most Recent" }[language]}
              onClick={() =>
                setOrderBy({ pt: "Mais recente", en: "Most Recent" })
              }
            />
            <FormControlLabel
              value={
                {
                  pt: "Ordem alfabética [A-Z]",
                  en: "Alphabetical Order [A-Z]",
                }[language]
              }
              control={<Radio color="default" />}
              label={
                {
                  pt: "Ordem alfabética [A-Z]",
                  en: "Alphabetical Order [A-Z]",
                }[language]
              }
              onClick={() =>
                setOrderBy({
                  pt: "Ordem alfabética [A-Z]",
                  en: "Alphabetical Order [A-Z]",
                })
              }
            />
            <FormControlLabel
              value={
                {
                  pt: "Ordem alfabética [Z-A]",
                  en: "Alphabetical Order [Z-A]",
                }[language]
              }
              control={<Radio color="default" />}
              label={
                {
                  pt: "Ordem alfabética [Z-A]",
                  en: "Alphabetical Order [Z-A]",
                }[language]
              }
              onClick={() =>
                setOrderBy({
                  pt: "Ordem alfabética [Z-A]",
                  en: "Alphabetical Order [Z-A]",
                })
              }
            />
          </RadioGroup>
        </FormControl>
      </AccordionDetails>
    </Accordion>
  );
}

export default StoreOrderByFilter;
