import { createRef, useEffect, useState } from "react";
import styles from "./MediaDisplay.module.css";
import Carousel from "react-bootstrap/Carousel";
import close_icon from "../../resources/media/icons/portfolio/close.svg";
import { ProjectResponses } from "../../resources/api/Portfolio/Types";
import { APP_BACK_OFFICE_URL } from "../../resources/api/Api";

interface Props {
  imageIndex: number;
  mediaContent: ProjectResponses.Gallery[] | undefined;
  onClick: () => void;
}

export default function MediaDisplay({
  onClick,
  mediaContent,
  imageIndex,
}: Props) {
  const [activeIndex, setActiveIndex] = useState<number>(imageIndex);
  const videoRef = createRef<HTMLVideoElement>();
  const handleSelect = (selectedIndex: number) => {
    setActiveIndex(selectedIndex);
  };

  useEffect(() => {
    videoRef.current &&
      videoRef.current.parentElement?.className.split(" ").indexOf("active") &&
      videoRef.current.parentElement?.className.split(" ").indexOf("active") <
        0 &&
      videoRef.current.pause();
  });

  return (
    <div className={styles["popup"]}>
      <div
        className={styles["popup__background"]}
        onClick={() => onClick()}
      ></div>
      <Carousel
        interval={null}
        slide={false}
        activeIndex={activeIndex}
        indicators={false}
        onSelect={handleSelect}
      >
        {mediaContent?.map((media: ProjectResponses.Gallery, index: number) => {
          return (
            <Carousel.Item key={index}>
              <img
                alt="closeIcon"
                src={close_icon}
                className={styles["popup__close-icon"]}
                onClick={() => onClick()}
              />
              {media.fields.type === "video" ? (
                <video
                  className={styles["popup__media"]}
                  id="video"
                  controls
                  ref={videoRef}
                >
                  <source
                    src={`${APP_BACK_OFFICE_URL}nodes/${media.uuid}/binary/content#t=0.001`}
                    type="video/mp4"
                  />
                </video>
              ) : (
                <img
                  className={styles["popup__media"]}
                  src={`${APP_BACK_OFFICE_URL}nodes/${media.uuid}/binary/content`}
                  alt="popupimage"
                />
              )}
            </Carousel.Item>
          );
        })}
      </Carousel>
    </div>
  );
}
