import { useContext, useEffect, useState } from "react";
import cx from "classnames";
import styles from "./Toolbar.module.css";
// Components
import { Link, NavLink, useLocation } from "react-router-dom";
import HamburgerMenu from "react-hamburger-menu";
// Pages
// Context
import { LanguageContext } from "../../Context/LanguageContext";
// Hooks
import useWindowsSize from "../../Hooks/useWindowsSize";
// Pages
// Resources
import LinksData from "../Header/HeaderData";
import Logo from "../../resources/media/logos/Globaleda_logo.svg";
import LogoIcon from "../../resources/media/logos/Globaleda_logo_icon.svg";
import ROUTES from "../../resources/router/ROUTES";
import LanguageButton from "../LanguageButton/LanguageButton";
import SearchButton from "../SearchButton/SearchButton";

interface Props {
  drawerClickHandler: () => void;
  isOpen: boolean;
}

function Toolbar({ drawerClickHandler, isOpen }: Props) {
  const { language } = useContext(LanguageContext);
  const location = useLocation();
  const size = useWindowsSize();
  const [title, setTitle] = useState<string>("");

  useEffect(() => {
    LinksData.map(({ label, href }) => {
      if (location.pathname === href) {
        return setTitle(label[language]);
      } else if (location.pathname === ROUTES.INDEX) {
        return setTitle("");
      }
      return "";
    });
  }, [location.pathname, language]);

  return (
    <header className={styles["toolbar"]}>
      <nav className={cx(styles["toolbar__navigation"], "portal-container")}>
        <div className={styles["toolbar__logo"]}>
          <Link to={ROUTES.INDEX}>
            {title === "" || size.width >= 1201 ? (
              <img src={Logo} alt="logo" height="200" width="200" />
            ) : (
              <img src={LogoIcon} alt="logo" height="200" width="200" />
            )}
          </Link>
          <h6>{title}</h6>
        </div>
        <div className={styles["toolbar__navigation-items"]}>
          <ul>
            {LinksData.map(({ label, href, subLinks }) => {
              if (href != ROUTES.SEARCH)
              return (
                <li key={label[language]}>
                  {label[language] ===
                  { pt: "LOJA ONLINE", en: "ONLINE SHOP" }[language] ? (
                    <a
                      href="https://lojaglobaleda.pt/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {label[language]}
                    </a>
                  ) : (
                    <NavLink activeClassName={cx(styles["active"])} to={href}>
                      {label[language]}
                    </NavLink>
                  )}
                  {subLinks && (
                    <div className={styles["dropdown-content"]}>
                      {subLinks.map((link, index: number) => {
                        return (
                          <NavLink to={link.href} key={index}>
                            {link.label[language]}
                          </NavLink>
                        );
                      })}
                    </div>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
        <div className={styles["spacer"]} />
        <div className={styles["toolbar__side-options"]}>
          <LanguageButton mobileHide={true} />
          <SearchButton />
        </div>
        <div className={styles["toolbar__toggle-button"]}>
          <HamburgerMenu
            isOpen={isOpen}
            menuClicked={drawerClickHandler}
            strokeWidth={3}
            rotate={180}
            color="var(--background-color-3)"
            borderRadius={0}
            animationDuration={0.3}
          />
        </div>
      </nav>
    </header>
  );
}

export default Toolbar;
