import React, { createContext, useEffect, useState } from "react";
// Components
// Context
// Hooks
// Pages
// Resources

type languageT = "pt" | "en";

interface Context {
  language: languageT;
  setLanguage: (language: languageT) => void;
}

const getBrowserLanguage = (): languageT => {
  var browserLanguage = navigator.language;

  console.log(browserLanguage);
  if (browserLanguage === "pt-PT") return "pt";

  return "en";
};

const initialState: languageT =
  (localStorage.getItem("language") as languageT) || getBrowserLanguage();

const LanguageContext = createContext<Context>({
  language: initialState,
  setLanguage: () => null,
});

interface Props {
  children: React.ReactNode;
}

function LanguageProvider({ children }: Props) {
  const [language, setLanguage] = useState<languageT>(initialState);

  useEffect(() => {
    localStorage.setItem("language", language);
  }, [language]);

  return (
    <LanguageContext.Provider value={{ language, setLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
}

export { LanguageContext, LanguageProvider };
