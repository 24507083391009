import { AxiosResponse } from "axios";
import { backOfficeAPI } from "../Api";
import { Partners, PartnershipsPage, Partner } from "./Types";

export const PartnerAPI = () => ({
  getPartners: async (): Promise<AxiosResponse<Partners.RootObject>> =>
    new Promise((resolve, reject) => {
      backOfficeAPI.axios
        .post("/graphql", {
          query: `{
  node(path: "/parcerias") {
    children(filter: {schema: {is: partner}} version: published) {
      elements {
        uuid
        displayName
        created
        ... on partner {
          fields {
            directLink
            link
            index
          }
        }
      }
    }
  }
}
`,
        })
        .then((response: AxiosResponse<Partners.RootObject>) => {
          resolve(response);
        });
    }),
  getPartnersShipPage: async (
    lang: string
  ): Promise<AxiosResponse<PartnershipsPage.RootObject>> =>
    new Promise((resolve, reject) => {
      backOfficeAPI.axios
        .post("/graphql", {
          query: `
            {
  node(uuid: "d563abda867d4cacb58fa0025f5f1d38", lang: "${lang}", version: published) {
    uuid
    ... on partnershipsPage {
      fields {
        advantagesText
        advantagesTitle
        text
        title
      }
    }
  }
}
 `,
        })
        .then((response: AxiosResponse<PartnershipsPage.RootObject>) => {
          resolve(response);
        });
    }),
  getPartner: async (
    lang: string,
    uuid: string
  ): Promise<AxiosResponse<Partner.RootObject>> =>
    new Promise((resolve, reject) => {
      backOfficeAPI.axios
        .post("/graphql", {
          query: `
            {
          node(uuid: "${uuid}", lang: "${lang}", version: published) {
            ... on partner {
              uuid
              fields {
                introduction
                longDescription
                name
                link
                projects {
                  ... on project {
                    uuid
                    fields {
                      date
                      title
                      color
                    }
                  }
                }
                title
              }
            }
          }
        }`,
        })
        .then((response: AxiosResponse<Partner.RootObject>) => {
          resolve(response);
        });
    }),
});
