import React from "react";
import styles from "./NotFound.module.css";
// Components
// Context
// Hooks
// Pages
// Resources

export default function NotFound() {
  return <div className={styles["not-found"]}>404 Component</div>;
}
