import styles from "./AnniversaryPage.module.css";
import cs from "classnames";
import { useEffect } from "react";
import waves from "../../resources/media/images/aboutUs/waves-06.svg";
import logo25 from "../../resources/media/images/logo25.png";

export default function AnniversaryPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles["mensagem"]}>
      <div className={styles["title_row"]}>
        <h2>Mensagem do Conselho de Administração</h2>
        <img src={logo25} alt="logo" />
      </div>
      <p className={styles["first-line"]}>
        <span lang="pt">
          Tudo come&ccedil;ou a 23 de setembro de 1997, data da
          constitui&ccedil;&atilde;o formal da GLOBALEDA. Certamente antes,
          Monteiro da Silva, na altura Presidente da EDA, visionou que o mercado
          dos telem&oacute;veis seria um neg&oacute;cio com futuro.
        </span>
      </p>
      <p>
        <span lang="pt">
          Desafiou Paulo Moniz, que juntou na &ldquo;equipa fundadora&rdquo;
          Ant&oacute;nio Brilhante, No&eacute;mia Luz, Eug&eacute;nio Alves e
          Lino Martins. Se hoje somos mais de 100 colaboradores, h&aacute; 25
          anos, tudo come&ccedil;ou com estes 5 jovens quadros da EDA.
        </span>
      </p>
      <p>
        <span lang="pt">
          Grande maratona! Se em 2022 temos a responsabilidade de a liderar, a
          GLOBALEDA, 25 anos depois, como empresa de refer&ecirc;ncia no mercado
          regional e nacional, nas &aacute;reas das Comunica&ccedil;&otilde;es,
          Telecomunica&ccedil;&otilde;es e Sistemas de Informa&ccedil;&atilde;o,
          &eacute; o resultado do contributo e compet&ecirc;ncia dos seus
          colaboradores e da vis&atilde;o estrat&eacute;gica de todos os seus
          administradores.
        </span>
      </p>
      <p>
        <span lang="pt">
          Hoje, num mercado global<strong>&nbsp;</strong>e muito concorrencial,
          a motiva&ccedil;&atilde;o dos que, diariamente, aqui trabalham,
          permite-nos manter o foco na constante evolu&ccedil;&atilde;o dos
          mercados onde atuamos. Assumimos o imperativo de inovarmos nos
          servi&ccedil;os e nos produtos que concebemos e materializamos, com a
          vantagem inequ&iacute;voca de juntarmos, na mesma empresa,
          compet&ecirc;ncias nas &aacute;reas dos Sistemas de
          Informa&ccedil;&atilde;o e das Telecomunica&ccedil;&otilde;es.
        </span>
      </p>
      <p>
        <span lang="pt">
          A GLOBALEDA est&aacute; em todas as ilhas e no continente
          portugu&ecirc;s. &Eacute; outra vantagem competitiva. Garantimos
          proximidade e confian&ccedil;a. S&oacute; assim, podemos ajudar os
          nossos clientes a customizar solu&ccedil;&otilde;es bem dimensionadas
          e sem desperd&iacute;cio de recursos.
        </span>
      </p>
      <p>
        <span lang="pt">
          A cada dia, temos mais aplica&ccedil;&otilde;es suportadas nos
          Sistemas de Informa&ccedil;&atilde;o, que associam a competitividade
          das empresas &agrave; &ldquo;comodidade do cidad&atilde;o&rdquo;.
          Estamos a&iacute;!
        </span>
      </p>
      <p>
        <span lang="pt">
          Somos l&iacute;deres regionais na engenharia e
          constru&ccedil;&atilde;o de redes de r&aacute;dio. Agora o objetivo
          ser&aacute; otimizar esta tecnologia, disponibilizando aos decisores
          informa&ccedil;&atilde;o relevante para a gest&atilde;o dos
          territ&oacute;rios, enquanto&nbsp;
          <em>smart cities ou smart islands</em>. Queremos fazer!
        </span>
      </p>
      <p>
        <span lang="pt">
          A Transi&ccedil;&atilde;o Digital &eacute; a oportunidade para
          fazermos desaparecer o&nbsp;<em>handicap</em>&nbsp;da nossa geografia.
        </span>
      </p>
      <p>
        <span lang="pt">
          No digital, n&atilde;o &eacute; a geografia que determina os
          &ldquo;centros&rdquo; ou as &ldquo;periferias&rdquo;. Os
          &ldquo;centros&rdquo; ser&atilde;o aqueles que conseguirem estabelecer
          boas infraestruturas tecnol&oacute;gicas, capazes de atrair e reter os
          recursos mais competentes. Somos agentes ativos!
        </span>
      </p>
      <p>
        <span lang="pt">
          &Eacute; uma impossibilidade pr&aacute;tica, querer ter
          t&eacute;cnicos especialistas em tudo, para tudo querer fazer.
        </span>
      </p>
      <p>
        <span lang="pt">
          Por isto, a GLOBALEDA construiu parcerias com a TETRAPI &ndash;
          Creative Solutions, DELL, GOOGLE, SAP, VODAFONE, CISCO, AXIANS,
          MOTOROLA, AQUASIS, ZONA DE IDEIAS, ITRON e Universidade de Aveiro. A
          Universidade dos A&ccedil;ores &eacute; tamb&eacute;m uma prioridade.
          O objetivo &eacute; o de entregar ao Cliente a melhor
          solu&ccedil;&atilde;o, criando e retendo&nbsp;<em>know-how</em>
          &nbsp;nesta Regi&atilde;o Aut&oacute;noma.
        </span>
      </p>
      <p>
        <span lang="pt">
          Com um percurso de 25 anos, acrescentamos agilidade ao sentimento de
          perten&ccedil;a e &agrave; nossa cultura de colabora&ccedil;&atilde;o.
          Com esta cultura, vamos alcan&ccedil;ar objetivos, mesmo que
          aparentemente inating&iacute;veis, porque afirmamos, com
          confian&ccedil;a, que O FUTURO FAZEMOS N&Oacute;S!
        </span>
      </p>
      <p>
        <span lang="pt">
          Neste dia, quando a GLOBALEDA comemora 25 anos, obrigado a todos os
          nossos Clientes, Parceiros, Colaboradores e Acionistas que fizeram
          este percurso, e que querem continu&aacute;-lo connosco.
        </span>
      </p>
      <p>
        <span lang="pt">&nbsp;</span>
      </p>
      <p className={styles["signature"]}>
        <span lang="pt">
          O Conselho de Administra&ccedil;&atilde;o da GLOBALEDA, S.A.
        </span>
      </p>
      <p>
        <span lang="pt">&nbsp;</span>
      </p>
      <p className={styles["signature"]}>
        <span lang="pt">Jorge Macedo, Vitor Costa e F&aacute;bio Costa</span>
      </p>
      <img src={waves} className={styles["waves"]} alt="waves" />
    </div>
  );
}
