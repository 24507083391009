import React, { useState } from "react";
import styles from "./SearchButton.module.css";
// Components
// Context
// Hooks
// Pages
// Resources
import search_icon from "../../resources/media/icons/header/search.svg";
import { Link } from "react-router-dom";
import ROUTES from "../../resources/router/ROUTES";

function SearchButton() {
  return (
    <div>
      <Link to={ROUTES.SEARCH}>
        <img
          alt="search-icon"
          src={search_icon}
          className={styles["toolbar__search-icon"]}
        />
      </Link>
    </div>
  );
}

export default SearchButton;
