import cx from "classnames";
import styles from "./PortfolioFilter.module.css";
// Components
import Loader from "../../../Components/Loader/Loader";
// Context
import { LanguageContext } from "../../../Context/LanguageContext";
// Hooks
import { useQuery } from "react-query";
import { useContext, useEffect, useState } from "react";
// Pages
// Resources
import { ApiInterface } from "../../../resources/api/Api";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ClickAwayListener,
} from "@material-ui/core";

interface Props {
  area: string;
  setArea: (area: string) => void;
}

function PortfolioFilter({ area, setArea }: Props) {
  const { language } = useContext(LanguageContext);
  const [open, setOpen] = useState<boolean>(false);
  const [allProjectsCount, setAllProjectsCount] = useState<number>(0);
  const { data, isFetching } = useQuery(
    ["portfolioAreas", language],
    () => ApiInterface().portfolio.getAreas(language),
    {
      retry: 10,
      retryDelay: 500,
    }
  );

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  useEffect(() => {
    setAllProjectsCount(0);
    data?.data.data.node.children.elements.map((area) => {
      return setAllProjectsCount(
        (prevState) => prevState + area.children.totalCount
      );
    });
  }, [data]);

  return (
    <ClickAwayListener
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
      onClickAway={handleClickAway}
    >
      <Accordion
        className={styles["portfolio__filter-container"]}
        expanded={open}
      >
        <AccordionSummary
          className={styles["portfolio__filter-title"]}
          expandIcon={<ExpandMoreIcon />}
          onClick={handleClick}
        >
          <h6>
            {area
              ? area
              : { pt: "Filtrar por Ofertas", en: "Filter by Offers" }[language]}
          </h6>
        </AccordionSummary>
        <AccordionDetails className={styles["portfolio__filter-options"]}>
          <div
            onClick={() => setArea("Globaleda")}
            style={{ marginTop: "1em" }}
            className={cx(styles["portfolio__filter-option"], styles["gray"])}
            key="globaleda"
          >
            {{ pt: "Geral", en: "All" }[language]}
            <sup>{allProjectsCount}</sup>
          </div>
          {isFetching ? (
            <Loader />
          ) : !data ? null : (
            data.data.data.node.children.elements.map((area, index) => {
              return (
                <div
                  onClick={() => setArea(area.displayName)}
                  className={cx(
                    styles["portfolio__filter-option"],
                    area.fields.color && styles[area.fields.color]
                  )}
                  key={index}
                >
                  {area.displayName}
                  <sup>{area.children.totalCount}</sup>
                </div>
              );
            })
          )}
        </AccordionDetails>
      </Accordion>
    </ClickAwayListener>
  );
}

export default PortfolioFilter;
