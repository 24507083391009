import React, { useState } from "react";
import styles from "./AccordionItem.module.css";
import cx from "classnames";
// Components
// Context
// Pages
//Resources
import arrow_down from "../../../resources/media/icons/header/arrow_down.svg";
import { ACCORDION } from "../../../resources/typing/enumerates";

interface Props {
  children: React.ReactNode;
  title: string;
  titleSize: string;
  isCollapsed?: boolean;
  handleClick?: () => void;
  type?: ACCORDION;
  id?: string;
  id2?: string;
}

export default function AccordionItem({
  children,
  title,
  titleSize,
  isCollapsed,
  handleClick,
  type,
  id,
  id2,
}: Props) {
  const [isOpen, setIsOpen] = useState(isCollapsed);

  return (
    <React.Fragment>
      <button
        onClick={
          type === ACCORDION.SIMPLE
            ? () => setIsOpen((isOpen) => !isOpen)
            : handleClick
        }
        className={styles["accordion-item__filter"]}
        style={{ fontSize: titleSize }}
        id={id2}
      >
        {title}
        <img
          alt="arrow"
          src={arrow_down}
          className={cx(
            styles["accordion-item__arrow-icon"],
            (type === ACCORDION.SIMPLE ? isOpen : isCollapsed) &&
              styles["accordion-item__arrow-icon--inverse"]
          )}
        />
      </button>
      <div
        className={cx(
          styles["children-container"],
          (type === ACCORDION.SIMPLE ? isOpen : isCollapsed) &&
            styles["children-container--show"]
        )}
        id={id}
      >
        {children}
      </div>
    </React.Fragment>
  );
}
