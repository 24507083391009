import { AxiosResponse } from "axios";
import { backOfficeAPI } from "../Api";
import { Documents, PrivacyPolicy } from "./Types";

export const DocumentsApi = () => ({
  getDocuments: async (
    lang: string
  ): Promise<AxiosResponse<Documents.RootObject>> =>
    new Promise((resolve, reject) => {
      backOfficeAPI.axios
        .post("/graphql", {
          query: `
            {
                node(path: "/documentos") {
                    children(filter: {schema: {is: folder}}, lang: "${lang}") {
                        elements {
                            displayName
                            created
                            ... on folder {
                              fields {
                                slug
                              }
                            }
                            children(version: published, lang: "${lang}") {
                                elements {
                                    __typename
                                    displayName
                                    created
                                    ... on document {
                                        uuid
                                        fields {
                                            name
                                            document {
                                                binaryUuid
                                            }
                                        }
                                    }
                                    ... on folder {
                                      fields {
                                        slug
                                      }
                                    }
                                    children(version: published, lang: "${lang}") {
                                      elements {
                                        created
                                          ... on document {
                                              uuid
                                              fields {
                                                  name
                                                  document {
                                                      binaryUuid
                                                  }
                                              }
                                          }
                                      }
                                  }
                                }
                            }
                        }
                    }
                }
            }`,
        })
        .then((response: AxiosResponse<Documents.RootObject>) => {
          resolve(response);
        });
    }),
  getPrivacyPolicy: async (
    lang: string
  ): Promise<AxiosResponse<PrivacyPolicy.RootObject>> =>
    new Promise((resolve, reject) => {
      backOfficeAPI.axios
        .post("/graphql", {
          query: `
            {
  node(path: "/documentos") {
    children(filter: {schema: {is: privacyPolicy}}, lang: "${lang}") {
      elements {
        displayName
        ... on privacyPolicy {
          fields {
            privacyPolicy
          }
        }
      }
    }
  }
}
`,
        })
        .then((response: AxiosResponse<PrivacyPolicy.RootObject>) => {
          resolve(response);
        });
    }),
});
