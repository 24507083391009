import { useContext } from "react";
import cx from "classnames";
import styles from "./SideDrawer.module.css";
// Components
import { NavLink } from "react-router-dom";
// Pages
// Context
import { LanguageContext } from "../../Context/LanguageContext";
// Hooks
// Pages
// Resources
import LinksData from "../Header/HeaderData";
import Portugal from "../../resources/media/icons/portugal.svg";
import UK from "../../resources/media/icons/united-kingdom.svg";
import ROUTES from "../../resources/router/ROUTES";
import LanguageButton from "../LanguageButton/LanguageButton";

interface Props {
  show: boolean;
}

function SideDrawer({ show }: Props) {
  const { language, setLanguage } = useContext(LanguageContext);

  return (
    <nav className={cx(styles["side-drawer"], show && styles["open"])}>
      <ul>
        <li>
          <div className={styles["language-buttons"]}>
            <LanguageButton mobileHide={false}></LanguageButton>
          </div>
        </li>
        
        {LinksData.map(({ label, href, subLinks }) => {
          if (href != ROUTES.SEARCH)
          return !subLinks ? (
            <li key={label[language]}>
              {label[language] ===
              { pt: "LOJA ONLINE", en: "ONLINE SHOP" }[language] ? (
                <a href="https://lojaglobaleda.pt/" target="_blank" rel="noreferrer">
                  {label[language]}
                </a>
              ) : (
                <NavLink activeClassName={cx(styles["active"])} to={href}>
                  {label[language]}
                </NavLink>
              )}
            </li>
          ) : (
            <li key={label[language]}>
              <button className={styles["button"]}>
                {label[language]}
                <div className={styles["dropdown-content"]}>
                  {subLinks.map((link, index: number) => {
                    return (
                      <NavLink to={link.href} key={index}>
                        {link.label[language]}
                      </NavLink>
                    );
                  })}
                </div>
              </button>
            </li>
          );
        })}
      </ul>
    </nav>
  );
}

export default SideDrawer;
