import React, { useState, useContext } from "react";
import styles from "./ContactsPopupContent.module.css";
import { useForm } from "react-hook-form";
//Resources
import arrow from "../../../resources/media/icons/store/return_arrow.svg";
import PopupInformation from "../PopupInformation.json";

// Context
import { LanguageContext } from "../../../Context/LanguageContext";

//Components
import SuccessPopupContent from "../SuccessPopupContent/SuccessPopupContent";
import PrivacyStatement from "../../PrivacyStatement/PrivacyStatement";
import Loader from "../../Loader/Loader";

interface Props {
  closePopup: () => void;
}
export default function ContactsPopupContent({ closePopup }: Props) {
  const { language } = useContext(LanguageContext);
  const [isFormCorrect, setIsFormCorrect] = useState<boolean>(false);
  const [showTermsAndConditions, setShowTermsAndConditions] =
    useState<boolean>(false);
  const { register, handleSubmit, errors } = useForm();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const requiredMessage = PopupInformation.requiredError[language];

  const onSubmit = (data: {}, event: any) => {
    // alert(JSON.stringify(data));
    // setIsLoading(true);
    sendMessage(event);
  };

  const sendMessage = (event: any) => {
    event.preventDefault();
    let mailto = `mailto:comunicacoes@globaleda.pt?cc=${event.target.email.value}&subject=Comunicação à Globaleda&body=Empresa:${event.target.empresa.value}%0D%0A%0D%0A%0D%0AMensagem:%0D%0A${encodeURIComponent(event.target.message.value)}`;
    window.open(mailto);
  };

  if (isLoading) {
    return <Loader></Loader>;
  }

  return (
    <>
      {isFormCorrect ? (
        <SuccessPopupContent closePopup={() => closePopup()} />
      ) : (
        <div>
          {showTermsAndConditions && (
            <div className={styles["terms-and-conditions"]}>
              <img
                onClick={() => setShowTermsAndConditions(false)}
                alt="arrow"
                src={arrow}
                className={styles["return-arrow"]}
              />
              <PrivacyStatement />
            </div>
          )}
          <div className={styles["contact-form"]}>
            <h2>{PopupInformation.contactTitle[language]}</h2>
            <p>{PopupInformation.contactSubtitle[language]}</p>
            <form onSubmit={handleSubmit(onSubmit)}>
              <label className={styles["contact-form__input-field"]}>
                {PopupInformation.nameField[language]}
                <input
                  name="name"
                  ref={register({ required: requiredMessage })}
                  placeholder="Lorem Ipsum"
                />
                {errors.name && (
                  <h6 className={styles["contact-form__error-message"]}>
                    {errors.name.message}
                  </h6>
                )}
              </label>
              <label className={styles["contact-form__input-field"]}>
                {PopupInformation.emailField[language]}
                <input
                  name="email"
                  ref={register({
                    required: requiredMessage,
                    pattern: {
                      value: /^\S+@\S+$/i,
                      message: PopupInformation.emailError[language],
                    },
                  })}
                  placeholder={PopupInformation.emailPlaceholder[language]}
                />
                {errors.email && (
                  <h6 className={styles["contact-form__error-message"]}>
                    {errors.email.message}
                  </h6>
                )}
              </label>
              <label className={styles["contact-form__input-field"]}>
                {PopupInformation.companyField[language]}
                <input
                  name="empresa"
                  placeholder="Lorem Ipsum"
                  ref={register({})}
                />
                {errors.empresa && (
                  <h6 className={styles["contact-form__error-message"]}>
                    {errors.empresa.message}
                  </h6>
                )}
              </label>
              <label className={styles["contact-form__input-field"]}>
                <textarea
                  name="message"
                  ref={register({ required: requiredMessage })}
                  placeholder={PopupInformation.textArea[language]}
                />
                {errors.message && (
                  <h6 className={styles["contact-form__error-message"]}>
                    {errors.message.message}
                  </h6>
                )}
              </label>
              <label className={styles["contact-form__checkbox"]}>
                <input
                  type="checkbox"
                  name="termsAndConditions"
                  ref={register({
                    required:
                      PopupInformation.termsAndConditionsError[language],
                  })}
                />
                <h6>
                  {PopupInformation.termsAndConditions[language][0]}{" "}
                  <span onClick={() => setShowTermsAndConditions(true)}>
                    {PopupInformation.termsAndConditions[language][1]}
                  </span>
                </h6>
              </label>
              {errors.termsAndConditions && (
                <h6 className={styles["contact-form__error-message"]}>
                  {errors.termsAndConditions.message}
                </h6>
              )}
              <button type="submit">
                {PopupInformation.sendButton[language]}
              </button>
            </form>
          </div>
        </div>
      )}
    </>
  );
}
