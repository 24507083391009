const Routes = {
  INDEX: "/",
  ABOUT: "/sobre",
  DOCUMENTS: "/sobre/documentos",
  OFFERS: "/oferta",
  OFFER: function (id?: string) {
    if (id) return `${this.OFFERS}/${id}`;
    else return `${this.OFFERS}/:id`;
  },
  PARTNERSHIPS: "/parcerias",
  PARTNER_PAGE: function (id?: string) {
    if (id) return `${this.PARTNERSHIPS}/${id}`;
    else return `${this.PARTNERSHIPS}/:id`;
  },
  PORTFOLIO: "/portfolio",
  PORTFOLIO_PAGE: function (id?: string) {
    if (id) return `${this.PORTFOLIO}/${id}`;
    else return `${this.PORTFOLIO}/:id`;
  },
  CAREERS: "/carreiras",
  CONTACTS: "/contatos",
  STORE: "/loja",
  STORE_ITEM: function (id?: string) {
    if (id) return `${this.STORE}/${id}`;
    else return `${this.STORE}/:id`;
  },
  SEARCH: "/pesquisa",
  SEARCH_TEXT: function (searchText?:string) {
    if (searchText) return `${this.SEARCH}/${searchText}`;
    else return `${this.SEARCH}/:searchText`;
  },
  ANNIVERSARY: "/aniversario",
  NOT_FOUND: "/404",
};

export default Routes;
