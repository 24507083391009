import styles from "./PrivacyStatement.module.css";
// Components
// Context
import { LanguageContext } from "../../Context/LanguageContext";
// Hooks
import { useQuery } from "react-query";
import { useContext, useEffect } from "react";
// Pages
// Resources
import { ApiInterface } from "../../resources/api/Api";
import Loader from "../Loader/Loader";

export default function PrivacyStatement() {
  const { language } = useContext(LanguageContext);
  const { data, isFetching } = useQuery(["privacyPolicy", language], () =>
    ApiInterface().documents.getPrivacyPolicy(language)
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (isFetching) {
    return <Loader />;
  }

  if (!data) {
    return null;
  }

  return (
    <div className={styles["documents__privacy-policy"]}>
      {data.data.data.node.children.elements.map((object, index) => {
        return (
          <div key={index}>
            <dd
              dangerouslySetInnerHTML={{ __html: object.fields.privacyPolicy }}
            />
          </div>
        );
      })}
    </div>
  );
}
