import { AxiosResponse } from "axios";
import { backOfficeAPI } from "../Api";
import { SearchResults } from "./Types";

export const SearchApi = () => ({
  searchGraphQL: async (searchText: string): Promise<AxiosResponse<any>> =>
    new Promise((resolve, reject) => {
      backOfficeAPI.axios
        .post("/graphql", {
          query: `{
            #//   nodes(perPage: 100, page: 1, query: "{\"query\":{\"query_string\":{\"query\":\"${searchText}\"}}}") {
                nodes(perPage: 100, page: 1
                    #,filter: {schema: { is: document }}
                    ,query: \"{\\\"query\\\":{\\\"query_string\\\":{\\\"query\\\":\\\"sistema\\\"}}}\") {
            elements {
                  uuid
                  
                  
                }
                totalCount
                pageCount
                size
                perPage
                hasNextPage
                hasPreviousPage
                currentPage
              }
            }`,
        })
        .then((response: AxiosResponse<any>) => {
          resolve(response);
        });
    }),
  search: async (
    searchText: string,
    language: string
  ): Promise<AxiosResponse<SearchResults.RootObject>> =>
    new Promise((resolve, reject) => {
      backOfficeAPI.axios
        .post("/search/nodes?perPage=50&version=published&lang="+language, {
          query: {
            query_string: { query: searchText },
            // bool: {
            //   filter: [{
            //     term: { "schema.name": "document" },
            //   },],
            // },
          },
          sort: [
            //   { created: "asc" },
               "_score"],
        })
        .then((response: AxiosResponse<SearchResults.RootObject>) => {
          resolve(response);
        });
    }),
});
