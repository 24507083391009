import { AxiosResponse } from "axios";
import { backOfficeAPI } from "../Api";
import { Offers, Offer, OfferIntro } from "./Types";

export const OffersAPI = () => ({
  getOffers: async (lang: string): Promise<AxiosResponse<Offers.RootObject>> =>
    new Promise((resolve, reject) => {
      backOfficeAPI.axios
        .post("/graphql", {
          query: `
            {
                node(path: "/oferta") {
                    children(lang: "${lang}") {
                        elements {
                            displayName
                            children(filter: {schema: {is : offer}}) {
                                elements {
                                    ... on offer {
                                        created
                                        uuid
                                        fields {
                                            title
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }`,
        })
        .then((response: AxiosResponse<Offers.RootObject>) => {
          resolve(response);
        });
    }),
  getOffersIntro: async (lang: string): Promise<AxiosResponse<OfferIntro.RootObject>> =>
    new Promise((resolve, reject) => {
      backOfficeAPI.axios
        .post("/graphql", {
          query: `
          {
            node(path: "/oferta") {
              children(lang: "${lang}", filter: {schema: {is: offerIntro}}) {
                elements {
                  displayName
                  ... on offerIntro {
                    uuid
                    fields {
                      title
                      body
                    }
                  }
                }
              }
            }
          }`,
        })
        .then((response: AxiosResponse<OfferIntro.RootObject>) => {
          resolve(response);
        });
    }),
  getOffer: async (
    uuid: string,
    lang: string
  ): Promise<AxiosResponse<Offer.RootObject>> =>
    new Promise((resolve, reject) => {
      backOfficeAPI.axios
        .post("/graphql", {
          query: `
            {
                node(uuid: "${uuid}", lang: "${lang}") {
                    ... on offer {
                        uuid
                        fields {
                            cards {
                                ... on offerCard {
                                    uuid
                                    fields {
                                        name
                                        text
                                        title
                                    }
                                }
                            }
                            color
                            list
                            text
                            title
                        }
                    }
                }
            }`,
        })
        .then((response: AxiosResponse<Offer.RootObject>) => {
          resolve(response);
        });
    }),
});
