import styles from "./HorizontalMenu.module.css";
import cx from "classnames";
// Components
// Context
// Hooks
import { useState } from "react";
// Pages
// Resources

interface HorizontalMenuProps {
  options: Array<string>;
  onClick: (index: number) => void;
}

export default function HorizontalMenu({
  options,
  onClick,
}: HorizontalMenuProps) {
  const [active, setActive] = useState<number>(0);

  return (
    <ul className={styles["horizontal-menu__options"]}>
      {options.map((option: string, index: number) => {
        return (
          <li key={index} onClick={() => setActive(index)}>
            <button
              onClick={() => onClick(index)}
              className={cx(
                styles["horizontal-menu__options-btn"],
                active === index &&
                  styles["horizontal-menu__options-btn--selected"]
              )}
            >
              {option}
            </button>
          </li>
        );
      })}
    </ul>
  );
}
