import styles from "./Popup.module.css";
// Components
// Hooks
import { createRef, cloneElement } from "react";
import useLockBodyScroll from "../../Hooks/useLockBodyScroll";
import useOnClickOutside from "../../Hooks/useOnClickOutside";
// Resources
import cross from "../../resources/media/icons/search/close.svg";
interface Props {
  children: React.ReactElement;
  closePopup: () => void;
}

export default function Popup({ children, closePopup }: Props) {
  const popupRef = createRef<HTMLDivElement>();
  useLockBodyScroll();
  useOnClickOutside(popupRef, () => closePopup());

  return (
    <section className={styles["popup-container"]}>
      <div className={styles["popup-container__content"]} ref={popupRef}>
        <div className={styles["close-button"]} onClick={closePopup}>
          <img src={cross} alt="cross" />
        </div>
        {/* {isDone ? (
          <SuccessPopupContent closePopup={closePopup} />
        ) : (
          React.cloneElement(children, { setIsDone })
        )} */}
        {cloneElement(children)}
      </div>
    </section>
  );
}
