import styles from "./StoreItemCarousel.module.css";
import cx from "classnames";
import Carousel from "react-bootstrap/Carousel";
// Hooks
import { useContext, useState } from "react";
//Resources
import arrow_left from "../../../../resources/media/icons/store/arrow_left.svg";
import arrow_right from "../../../../resources/media/icons/store/arrow_right.svg";
import { ProductResponses } from "../../../../resources/api/Store/Types";
import { LanguageContext } from "../../../../Context/LanguageContext";
import { APP_BACK_OFFICE_URL } from "../../../../resources/api/Api";

interface Props {
  product: ProductResponses.Data;
}

export default function StoreItemCarousel({ product }: Props) {
  const { language } = useContext(LanguageContext);
  const [activeIndex, setActiveIndex] = useState(0);

  const handleSelect = (selectedIndex: number) => {
    setActiveIndex(selectedIndex);
  };

  return (
    <div className={cx("carousel", styles["carousel"])}>
      <Carousel
        interval={null}
        fade={true}
        slide={false}
        activeIndex={activeIndex}
        onSelect={handleSelect}
        prevIcon={
          <img
            className={styles["carousel__prev-icon"]}
            src={arrow_left}
            alt=""
          />
        }
        nextIcon={
          <img
            className={styles["carousel__next-icon"]}
            src={arrow_right}
            alt=""
          />
        }
      >
        {product.node.fields.images.map((image, index: number) => {
          return index === 0 && product.node.fields.prevPrice ? (
            <Carousel.Item key={index}>
              <div className={styles["carousel__image-tag"]}>
                <h3>{{ pt: "Promoção", en: "Promotion" }[language]}</h3>
              </div>
              <div className={styles["carousel__image-discount"]}>
                <h3>
                  -
                  {`-${
                    product.node.fields.prevPrice - product.node.fields.price
                  }€`}
                </h3>
              </div>
              <img
                className={styles["carousel__image"]}
                src={`${APP_BACK_OFFICE_URL}nodes/${image.uuid}/binary/content?lang=pt&w=195&h=219`}
                alt="slide"
              />
            </Carousel.Item>
          ) : (
            <Carousel.Item key={index}>
              <img
                className={styles["carousel__image"]}
                src={`${APP_BACK_OFFICE_URL}nodes/${image.uuid}/binary/content?lang=pt&w=195&h=219`}
                alt="slide"
              />
            </Carousel.Item>
          );
        })}
      </Carousel>
      <div className={styles["carousel__thumbnails"]}>
        {product.node.fields.images.map((image, index) => {
          return (
            <img
              key={index}
              className={
                activeIndex === index
                  ? styles["carousel__thumbnails--selected"]
                  : styles[""]
              }
              src={`${APP_BACK_OFFICE_URL}nodes/${image.uuid}/binary/content?lang=pt&w=195&h=219`}
              alt=""
              onClick={() => setActiveIndex(index)}
            />
          );
        })}
      </div>
    </div>
  );
}
