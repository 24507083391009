import styles from "./Offer.module.css";
import cx from "classnames";
// Components
import Button from "../../../Components/Button/Button";
import ContactsPopupContent from "../../../Components/Popup/ContactsPopupContent/ContactsPopupContent";
import Loader from "../../../Components/Loader/Loader";
import Popup from "../../../Components/Popup/Popup";
// Context
import { LanguageContext } from "../../../Context/LanguageContext";
// Hooks
import { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
// Resources
import { ApiInterface, APP_BACK_OFFICE_URL } from "../../../resources/api/Api";

function Offer() {
  const { language } = useContext(LanguageContext);
  const { id: uuid } = useParams<{ id: string }>();
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const { data, isFetching } = useQuery(["offer", uuid, language], () =>
    ApiInterface().offers.getOffer(uuid, language)
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handlePopup = (): void => {
    setIsPopupOpen(!isPopupOpen);
  };

  if (isFetching) {
    return <Loader />;
  }

  if (!data) {
    return null;
  }

  return (
    <div>
      {isPopupOpen && (
        <Popup closePopup={handlePopup}>
          <ContactsPopupContent closePopup={handlePopup} />
        </Popup>
      )}
      <section
        className={cx(styles["section"], styles["first-section"])}
        style={{
          backgroundColor:
            data.data.data.node.fields.color === "red"
              ? "var( --background-color-2)"
              : data.data.data.node.fields.color === "blue"
              ? "var(--background-color-3)"
              : "var(--background-color-4)",
        }}
      >
        <div className={styles["first-section__image"]} style={{backgroundImage: `url(${APP_BACK_OFFICE_URL}nodes/${data.data.data.node.uuid}/binary/image)`}}></div>
        <div className={styles["first-section__text"]}
          style={{color: data.data.data.node.fields.color === "yellow" ? "var(--font-color-1)" : "white"}}>
          <h2>{data.data.data.node.fields.title}</h2>
          <p>{data.data.data.node.fields.text}</p>
          <ul>
            {data.data.data.node.fields.list.map((item, index: number) => {
              return <li key={index}>{item}</li>;
            })}
          </ul>
        </div>
      </section>
      {data.data.data.node.fields.cards.length !== 0 && (
        <section className={cx(styles["second-section"])}>
          <div className={styles["grid"]}>
            {data.data.data.node.fields.cards.map((card) => {
              console.log(`${APP_BACK_OFFICE_URL}nodes/${card.uuid}/binary/image`);
              return (
                <div className={styles["card"]} key={card.uuid}>
                  <img
                    src={`${APP_BACK_OFFICE_URL}nodes/${card.uuid}/binary/image`}
                    alt="cardImage"
                  />
                  <div className={styles["card__content"]}>
                    {card.fields.title && card.fields.title !== "" && (
                      <p className={styles["card__content-title"]}>
                        {card.fields.title}
                      </p>
                    )}
                    <p>{card.fields.text}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </section>
      )}
      <section
        className={cx(styles["section"], styles["third-section"])}
        style={{
          backgroundColor:
            data.data.data.node.fields.color === "red"
              ? "var( --background-color-2)"
              : data.data.data.node.fields.color === "blue"
              ? "var(--background-color-3)"
              : "var(--background-color-4)",
        }}
      >
        <div className={styles["third-section__image"]}></div>
        <div className={styles["third-section__text"]} style={{color: data.data.data.node.fields.color === "yellow" ? "var(--font-color-1)" : "white"}}>
          <h1>
            {
              {
                pt: "Vamos concretizar a sua ideia?",
                en: "Let's make dreams come true?",
              }[language]
            }
          </h1>
          <Button
            text={
              {
                pt: "Entrar em contato",
                en: "Get in touch",
              }[language]
            }
            color={
              data.data.data.node.fields.color === "red"
                ? "button--blue"
                : "button--red"
            }
            alignSelf={window.innerWidth < 575 ? "center" : "flex-start"}
            showArrow={true}
            onClick={handlePopup}
          />
        </div>
      </section>
    </div>
  );
}

export default Offer;
