import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import React from "react";
import ReactDOM from "react-dom";
import "./resources/fonts/stylesheet.css";
import "./index.css";
import App from "./Components/App/App";
/* Library CSS */
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function isIE() {
  const ua = window.navigator.userAgent; //Check the userAgent property of the window.navigator object
  const msie = ua.indexOf('MSIE '); // IE 10 or older
  const trident = ua.indexOf('Trident/'); //IE 11
  
  return (msie > 0 || trident > 0);
}
if(isIE())
  alert("Este Website não é suportado no Internet Explorer.\nThis website isn't supported on Internet Explorer.");

ReactDOM.render(
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
