import agilePT from "../../resources/media/images/services/agile_graphic_PT.png";
import agileEN from "../../resources/media/images/services/agile_graphic_EN.png";

const solutions = {
  firstSection: {
    title: {
      pt: "As nossas soluções",
      en: "Our solutions",
    },
    text: {
      pt: "Apostamos na inovação, implementamos as melhores práticas e desenvolvemos um conhecimento, comprovadamente sustentado, nas vertentes de comercialização, projeto, fornecimento, instalação, operação e manutenção nas áreas dos sistemas de informação e das telecomunicações.",
      en: "We invest in innovation, implement best practices and develop knowledge, proven to be sustained, in the areas of commercialization, design, supply, installation, operation and maintenance in the areas of information systems and telecommunications.",
    },
    firstCard: {
      pt: ["Lojas Globaleda", "Lojas Vodafone Indiretas", "Vodafone Business"],
      en: ["Globaleda Stores", "Vodafone Indirect Stores", "Vodafone Business"],
    },
    secondCard: {
      pt: [
        "Telecomunicações",
        "Planeamento de redes",
        "Estudos de Tráfego",
        "Concessão de Infraestruturas",
        "Recolha de Dados",
      ],
      en: [
        "Telecommunications",
        "Network planning",
        "Traffic Studies",
        "Infrastructure Concession",
        "Data collection",
      ],
    },
    thirdCard: {
      pt: [
        "Sistema de Informação",
        "Desenvolvimento Aplicacional",
        "Administração de Sistemas",
        "Desenho e Implementação de Redes",
        "Consultoria",
      ],
      en: [
        "Information system",
        "Application Development",
        "Systems Administration",
        "Design and Implementation of Networks",
        "Consultancy",
      ],
    },
  },
  secondSection: {
    title: {
      pt: "Acrescentamos valor ao seu produto.",
      en: "We add value to your product.",
    },
    subTitle: {
      pt: "Temos a equipa e as ferramentas necessárias para conceber o seu produto, desde a ideia inicial à implementação.",
      en: "We have the team and the tools necessary to design your product, from the initial idea to the implementation.",
    },
    text: {
      pt: "A GLOBALEDA está focada nos resultados dos seus projetos tecnológicos e na melhoria contínua dos processos, maximizando a eficiência e satisfação do cliente, respeitando o meio ambiente e contribuindo ativamente para o bem-estar das comunidades que serve e integra.",
      en: "GLOBALEDA is focused on the results of its technological projects and the continuous improvement of processes, maximizing efficiency and customer satisfaction, respecting the environment and actively contributing to the well-being of the communities it serves and integrates.",
    },
    redirectLinkText: {
      pt: "Saber mais sobre a Globaleda",
      en: "Know more about Globaleda",
    },
  },
  thirdSection: {
    title: {
      pt: "A nossa fórmula",
      en: "Our formula",
    },
    subTitle: {
      pt: "Metodologia Agile",
      en: "Agile Methodology",
    },
    text: {
      pt: "Trabalhamos de forma flexível e ágil, para garantir que o produto final seja o que o nosso cliente idealizou.",
      en: "We work in a flexible and agile way, to ensure that the final product meets what you have idealized.",
    },
    image: {
      pt: agilePT,
      en: agileEN,
    },
  },
  fourthSection: {
    title: {
      pt: "Vamos concretizar a sua ideia?",
      en: "Let's make dreams come true?",
    },
    buttonText: {
      pt: "Entrar em contato",
      en: "Get in touch",
    },
  },
};

export { solutions };
