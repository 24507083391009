import React, { useContext } from "react";
import styles from "./LanguageButton.module.css";
import cx from "classnames";
// Components
// Context
import { LanguageContext } from "../../Context/LanguageContext";
// Hooks
// Pages
// Resources
import Portugal from "../../resources/media/icons/portugal.svg";
import UK from "../../resources/media/icons/united-kingdom.svg";

interface Props{
  mobileHide: boolean;
}
function LanguageButton({mobileHide}: Props) {
  const { language, setLanguage } = useContext(LanguageContext);

  const handleClick = (lang: "pt" | "en") => {
    setLanguage(lang);
  };

  return (
    <div className={cx(styles["language-button__container"],
      mobileHide && styles["mobile-hide"]
    )}>
      <button
        className={cx(
          styles["language-button"],
          language == "pt" && styles["language-button-active"]
        )}
        onClick={() => handleClick("pt")}
      >
        PT
      </button>
      <button
        className={cx(styles["language-button"], language == "en" && styles["language-button-active"])}
        onClick={() => handleClick("en")}
      >
        ENG
      </button>
    </div>
  );
}

export default LanguageButton;
