import { useContext, useEffect } from "react";
import styles from "./Partnerships.module.css";
// Components
import Loader from "../../Components/Loader/Loader";
// Context
// Hooks
// Pages
// Resources
import { LanguageContext } from "../../Context/LanguageContext";
import { useQuery } from "react-query";
import { ApiInterface, APP_BACK_OFFICE_URL } from "../../resources/api/Api";
import { Link } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";
import moment from "moment";

function Partnerships() {
  const { language } = useContext(LanguageContext);
  const { data, isFetching } = useQuery(["partners"], () =>
    ApiInterface().partner.getPartners()
  );

  const { data: data2, isFetching: isFetching2 } = useQuery(
    ["partnersShipPage", language],
    () => ApiInterface().partner.getPartnersShipPage(language)
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (isFetching || isFetching2) {
    return <Loader />;
  }

  if (!data || !data2) {
    return null;
  }

  return (
    <div className={styles["partnerships"]}>
      <div className={styles["partnerships__intro-text"]}>
        <h3>{data2.data.data.node.fields.title}</h3>
        <p>
          <dd
            dangerouslySetInnerHTML={{
              __html: data2.data.data.node.fields.text,
            }}
          />
        </p>
        <div className={styles["partnerships__logos"]}>
          {data.data.data.node.children.elements
            .sort((a, b) => {
              return (
                (a.fields.index === null ? 1 : 0) - (b.fields.index === null ? 1 : 0)
                 ||
                a.fields.index - b.fields.index
              );
            })
            .map((partner, index: number) => {
              return (
                <Link
                  key={index}
                  to={
                    partner.fields.directLink
                      ? { pathname: partner.fields.link }
                      : "/parcerias/" + partner.uuid
                  }
                  target={partner.fields.directLink ? "_blank" : "_self"}
                >
                  <img
                    width="100%"
                    height="100%"
                    src={`${APP_BACK_OFFICE_URL}nodes/${partner.uuid}/binary/image`}
                    alt="partnerLogo"
                    className={styles["partnerships__logo-img"]}
                  />
                  <button className={styles["partnerships__button"]}>
                    {{ pt: "Ver mais", en: "See more" }[language]}
                    <BsArrowRight />
                  </button>
                </Link>
              );
            })}
        </div>
      </div>
      <div className={styles["partnerships__advantages"]}>
        <div
          className={styles["partnerships__image"]}
          style={{
            backgroundImage: `url(${APP_BACK_OFFICE_URL}nodes/${data2.data.data.node.uuid}/binary/image)`,
          }}
        ></div>
        <div className={styles["partnerships__text"]}>
          <h2>{data2.data.data.node.fields.advantagesTitle}</h2>
          <dd
            dangerouslySetInnerHTML={{
              __html: data2.data.data.node.fields.advantagesText,
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default Partnerships;
