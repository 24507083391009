import { AxiosResponse } from "axios";
import { backOfficeAPI } from "../Api";
import { HomepageResponses } from "./Types";

export const HomepageAPI = () => ({
  getContent: async (
    lang: string
  ): Promise<AxiosResponse<HomepageResponses.RootObject>> =>
    new Promise((resolve, reject) => {
      backOfficeAPI.axios
        .post("/graphql", {
          query: `
            {
                node(version: published, path:"/", ) {
                    children ( lang: "${lang}", version: published) {
                        elements {
                            uuid
                            schema {
                                name
                            }
                            ... on homePageSlide {
                              fields {
                                html
                                url
                                whiteText
                              }
                            }
                        }
                    }
                }
            }`,
        })
        .then((response: AxiosResponse<HomepageResponses.RootObject>) => {
          resolve(response);
        });
    }),
});
