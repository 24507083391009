import useWindowsSize from "./useWindowsSize";

export default function useColumnWidth(): string {
  const windowsSize = useWindowsSize();

  if (windowsSize.width <= 575) {
    return "100%";
  } else if (windowsSize.width <= 900) {
    return "50%";
  } else if (windowsSize.width <= 1200) {
    return "33%";
  } else {
    return "25%";
  }
}
