import styles from "../../Pages/Contacts/Contacts.module.css";
//Components
// Context
import { LanguageContext } from "../../Context/LanguageContext";
// Hooks
import { useContext } from "react";
// Pages
// Resources
import link_icon from "../../resources/media/icons/documents/link.svg";
import contactsAddress from "./contactsAddress.json";
import { Contacts } from "../../resources/api/Contacts/Types";

interface Props {
  address: Contacts.Element;
}

export default function ContactsAddress({ address }: Props): JSX.Element {
  const { language } = useContext(LanguageContext);

  return (
    <div className={styles["contacts__address"]}>
      <div className={styles["contacts__address-header"]}>
        <h4 className={styles["contacts__address-title"]}>
          {address.fields.name}
        </h4>
        <a href={address.fields.googleMaps} target="_blank" rel="noreferrer">
          {contactsAddress.buttonText[language]}
          <img
            alt=""
            src={link_icon}
            className={styles["contacts__link-icon"]}
          ></img>
        </a>
      </div>
      <div className={styles["contacts__address-info"]}>
        <p>{address.fields.address}</p>
        {address.fields.phoneNumber && <p>{address.fields.phoneNumber}</p>}
        {address.fields.email && <p>{address.fields.email}</p>}
      </div>
    </div>
  );
}
