import React from "react";
import styles from "./Button.module.css";
import cx from "classnames";
// Components
// Context
// Hooks
// Pages
// Resources
import arrow from "../../resources/media/icons/arrow.svg";

interface Props {
  text: string;
  color: string;
  width?: string;
  alignSelf?: string;
  showArrow: boolean;
  customizedClassName?: string;
  href?: string;
  onClick?: (event: any) => void;
}

export default function Button({
  text,
  color,
  width,
  alignSelf,
  showArrow,
  href,
  onClick,
}: Props) {
  return (
    <button
      className={cx(styles["button"], styles[color])}
      style={{ width: width, alignSelf: alignSelf }}
      onClick={onClick}
    >
      {href ? (
        <a href={`//${href}`} target="_blank" rel="noreferrer">
          {text}
        </a>
      ) : (
        text
      )}
      {showArrow && (
        <img alt="arrow" src={arrow} className={styles["button__arrow"]}></img>
      )}
    </button>
  );
}
