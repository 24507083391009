import styles from "./StoreItem.module.css";
import cx from "classnames";
// Components
import Loader from "../../../Components/Loader/Loader";
import { Link } from "react-router-dom";
import Popup from "../../../Components/Popup/Popup";
import StorePopupContent from "../../../Components/Popup/StorePopupContent/StorePopupContent";
import StoreItemCarousel from "../../../Components/Store/StoreItemPage/StoreItemCarousel/StoreItemCarousel";
import StoreItemInfo from "../../../Components/Store/StoreItemPage/StoreItemInfo/StoreItemInfo";
import Button from "../../../Components/Button/Button";
// Context
// Hooks
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
// Pages
// Resources
import { LanguageContext } from "../../../Context/LanguageContext";
import { ApiInterface } from "../../../resources/api/Api";
import arrow from "../../../resources/media/icons/store/return_arrow.svg";
import chipset_icon from "../../../resources/media/icons/store/chipset.svg";
import card_icon from "../../../resources/media/icons/store/sd_card.svg";
import phone_icon from "../../../resources/media/icons/store/smartphone.svg";

function StoreItem() {
  const { language } = useContext(LanguageContext);
  const { id: uuid } = useParams<{ id: string }>();
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [chosenIndex, setChosenIndex] = useState<number>(0);

  const { data, isFetching } = useQuery(
    ["product", uuid, language],
    () => ApiInterface().store.getProduct(uuid, language),
    { retry: 10, retryDelay: 500 }
  );

  let returnLink = (
    <Link to="/loja" className={styles["product__return-link"]}>
      <img className={styles["product__return-arrow"]} src={arrow} alt="" />
      Voltar a produtos
    </Link>
  );

  const handlePopup = (): void => {
    setIsPopupOpen(!isPopupOpen);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (isFetching) {
    return <Loader />;
  }

  if (!data) {
    return null;
  }

  const product = data.data.data;

  return (
    <>
      {isPopupOpen && (
        <Popup closePopup={handlePopup}>
          <StorePopupContent closePopup={() => handlePopup()} />
        </Popup>
      )}

      <div>
        {returnLink}
        <div className={styles["product"]}>
          <div className={styles["product__images-carousel"]}>
            <StoreItemCarousel product={product} />
          </div>
          <div className={styles["product__header"]}>
            <h3>{product.node.fields.name}</h3>
            <div className={styles["product__prices"]}>
              {product.node.fields.prevPrice && (
                <>
                  <div className={styles["product__price-now"]}>
                    <h5>{{ pt: "Agora", en: "Now" }[language]}</h5>
                    <h4>{product.node.fields.price}</h4>
                  </div>
                  <div className={styles["product__price-before"]}>
                    <h5>{{ pt: "Antes", en: "Before" }[language]}</h5>
                    <h4 className={styles["product__value-before"]}>
                      {product.node.fields.prevPrice}
                    </h4>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className={styles["product__info"]}>
            <p>
              {product.node.fields.description} - {product.node.fields.price}€
            </p>
            <div className={styles["product__features"]}>
              <SingleFeature
                source={chipset_icon}
                label={{ pt: "Processador", en: "Processor" }[language]}
                text={product.node.fields.processor}
              />
              <SingleFeature source={card_icon} label="Memória" text="64GB" />
              <SingleFeature
                source={phone_icon}
                label={{ pt: "Ecrã", en: "Screen" }[language]}
                text={product.node.fields.resolution}
              />

              <SingleFeature
                source={phone_icon}
                label={{ pt: "Câmera", en: "Camera" }[language]}
                text={product.node.fields.backCamera}
              />
            </div>
            <div className={styles["product__colors"]}>
              <h5>{{ pt: "Cores", en: "Colors" }[language]}</h5>
              <div className={styles["product__colors-container"]}>
                {product.node.fields.color.length !== 0 &&
                  product.node.fields.color.map((color, index) => {
                    let clr = color.split("/");
                    console.log(clr);

                    return (
                      <div
                        key={index}
                        className={cx(
                          styles["product__color-rectangle"],
                          chosenIndex === index &&
                            styles["product__color-rectangle--selected"]
                        )}
                        onClick={() => setChosenIndex(index)}
                      >
                        <div
                          className={styles["product__color-circle"]}
                          style={{ backgroundColor: clr[1] }}
                        />
                        <h6>{clr[0]}</h6>
                      </div>
                    );
                  })}
              </div>
            </div>
            <Button
              text={{ pt: "Encomendar", en: "Order" }[language]}
              color="button--red"
              showArrow={false}
              onClick={handlePopup}
            />
          </div>
        </div>
        <StoreItemInfo product={product} />
      </div>
    </>
  );
}

interface Props {
  source: string;
  label: string;
  text?: string;
}

function SingleFeature({ source, label, text }: Props) {
  return (
    <div className={styles["product__single-feature"]}>
      <img src={source} alt="icon" />
      <h6>{label}</h6>
      <h6>{text}</h6>
    </div>
  );
}

export default StoreItem;
