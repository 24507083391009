import React, { createRef, useContext, useState } from "react";
import styles from "./Homepage.module.css";
// Components
import Carousel from "react-bootstrap/Carousel";
import Loader from "../../Components/Loader/Loader";
// Context
// Hooks
import useWindowsSize from "../../Hooks/useWindowsSize";
import { useQuery } from "react-query";
// Pages
// Resources
import { LanguageContext } from "../../Context/LanguageContext";
import { ApiInterface } from "../../resources/api/Api";
import pause from "../../resources/media/images/homepage/pause.svg";
import play from "../../resources/media/images/homepage/play.svg";
import arrow_left from "../../resources/media/images/homepage/arrow_left.svg";
import arrow_right from "../../resources/media/images/homepage/arrow_right.svg";
import sound_off_video from "../../resources/media/icons/homepage/sound-off-video.svg";
import sound_on_video from "../../resources/media/icons/homepage/sound-on-video.svg";
import fullscreen from "../../resources/media/images/homepage/fullscreen-video.svg";
import { APP_BACK_OFFICE_URL } from "../../resources/api/Api";
import { CarouselItem } from "react-bootstrap";
import Button from "../../Components/Button/Button";
import CarouselCaption from "react-bootstrap/esm/CarouselCaption";
import { HomepageResponses } from "../../resources/api/Homepage/Types";
import { renderToString } from "react-dom/server";

export default function Homepage() {
  const size = useWindowsSize();
  const { language } = useContext(LanguageContext);
  const videoRef = createRef<HTMLVideoElement>();
  const [isPaused, setIsPaused] = useState<boolean>(false);
  const [isMuted, setIsMuted] = useState<boolean>(true);
  const { data, isFetching } = useQuery(["homepage", language], () =>
    ApiInterface().homepage.getContent(language)
  );

  const knowMoreBTNlabel = {
    pt: "Ler Mais",
    en: "Read More",
  };

  const controlVideo = (): void => {
    if (videoRef.current && videoRef.current.paused) {
      videoRef.current.play();
      setIsPaused(false);
    } else if (videoRef.current && videoRef.current.played) {
      videoRef.current.pause();
      setIsPaused(true);
    }
  };

  const toggleVideoVolume = () => {
    if (videoRef.current && videoRef.current.muted) {
      videoRef.current.muted = false;
      setIsMuted(false);
    } else if (videoRef.current && !videoRef.current.muted) {
      videoRef.current.muted = true;
      setIsMuted(true);
    }
  };

  const toggleFullScreen = () => {
    if (videoRef.current) {
      const video: any = videoRef.current;

      if (video.requestFullscreen) {
        video.requestFullscreen();
      } else if (video.mozRequestFullScreen) {
        video.mozRequestFullScreen();
      } else if (video.webkitRequestFullScreen) {
        video.webkitRequestFullScreen();
      } else if (video.msRequestFullscreen) {
        video.msRequestFullscreen();
      }
    }
  };

  function canDisplayFullscreen() {
    var videoel = document.createElement("video");
    const video: any = videoel;
    console.log(
      video.requestFullscreen ||
        video.mozRequestFullScreen ||
        video.webkitRequestFullScreen ||
        video.msRequestFullscreen
    );
    return (
      video.requestFullscreen ||
      video.mozRequestFullScreen ||
      video.webkitRequestFullScreen ||
      video.msRequestFullscreen
    );
  }

  function isMoreThanOneSlide(elements: any[]) {
    return elements.length > 1;
  }

  function getHTMLCaption(fields: HomepageResponses.Fields) {
    return {
      __html:
        fields.html +
        (fields.url
          ? renderToString(
              <Button
                color="button--red"
                width="10rem"
                showArrow={false}
                text={knowMoreBTNlabel[language]}
                href={`${fields.url}`}
              />
            )
          : ""
        ).toString(),
    };
  }

  if (isFetching) {
    return <Loader />;
  }

  if (!data) {
    return null;
  }

  return (
    <div className={styles["homepage"]}>
      <Carousel
        interval={null}
        slide={true}
        indicators={isMoreThanOneSlide(data.data.data.node.children.elements)}
        controls={isMoreThanOneSlide(data.data.data.node.children.elements)}
        prevIcon={
          <img
            width="100%"
            height="100%"
            src={arrow_left}
            alt="left arrow"
            className={styles["homepage__carousel-arrow"]}
          />
        }
        nextIcon={
          <img
            width="100%"
            height="100%"
            src={arrow_right}
            alt="right arrow"
            className={styles["homepage__carousel-arrow"]}
          />
        }
      >
        {data.data.data.node.children.elements.map((content, index) => {
          switch (content.schema.name) {
            case "image":
              return (
                <Carousel.Item key={index}>
                  <img
                    width="100%"
                    height="100%"
                    src={`${APP_BACK_OFFICE_URL}nodes/${content.uuid}/binary/image`}
                    alt="content"
                    className={styles["homepage__carousel-image"]}
                  />
                </Carousel.Item>
              );

            case "homePageSlide":
              console.log(content.fields.url);
              return (
                <CarouselItem
                  key={index}
                  style={{
                    backgroundImage: `url(${APP_BACK_OFFICE_URL}nodes/${content.uuid}/binary/image)`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                  }}
                >
                  <CarouselCaption
                    className={styles["homepage__carousel-slide__body"]}
                    style={{
                      color: content.fields.whiteText
                        ? "white"
                        : "var(--font-color-1)",
                    }}
                    dangerouslySetInnerHTML={getHTMLCaption(content.fields)}
                  ></CarouselCaption>
                </CarouselItem>
              );

            case "video":
              return (
                <Carousel.Item key={index}>
                  <video
                    id="full-screenVideo"
                    muted
                    autoPlay
                    loop
                    playsInline
                    className={styles["homepage__carousel-video"]}
                    key={`${APP_BACK_OFFICE_URL}nodes/${content.uuid}/binary/video`}
                    ref={videoRef}
                  >
                    <source
                      src={`${APP_BACK_OFFICE_URL}nodes/${content.uuid}/binary/video`}
                      type="video/mp4"
                    />
                  </video>
                  <div className={styles["homepage__carousel-video__buttons"]}>
                    <button
                      onClick={toggleVideoVolume}
                      className={styles["homepage__carousel-video__button"]}
                    >
                      {isMuted ? (
                        <img
                          width="100%"
                          height="100%"
                          src={sound_off_video}
                          alt="play button"
                        />
                      ) : (
                        <img
                          width="100%"
                          height="100%"
                          src={sound_on_video}
                          alt="pause button"
                        />
                      )}
                    </button>
                    <button
                      style={{
                        display: canDisplayFullscreen()
                          ? "inline"
                          : "none",
                      }}
                      onClick={toggleFullScreen}
                      className={styles["homepage__carousel-video__button"]}
                    >
                      <img
                        width="100%"
                        height="100%"
                        src={fullscreen}
                        alt="fullscreen button"
                      />
                    </button>
                    <button
                      onClick={controlVideo}
                      className={styles["homepage__carousel-video__button"]}
                    >
                      {isPaused ? (
                        <img
                          width="100%"
                          height="100%"
                          src={play}
                          alt="play button"
                        />
                      ) : (
                        <img
                          width="100%"
                          height="100%"
                          src={pause}
                          alt="pause button"
                        />
                      )}
                    </button>
                  </div>
                </Carousel.Item>
              );
          }
        })}
      </Carousel>
    </div>
  );
}
