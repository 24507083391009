import React, { useContext } from "react";
import styles from "./StoreAccordionFilter.module.css";
// Components
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Loader from "../../../../Components/Loader/Loader";
// Context
import { LanguageContext } from "../../../../Context/LanguageContext";
// Hooks
import { useQuery } from "react-query";
// Pages
// Resources
import { ApiInterface } from "../../../../resources/api/Api";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { FormGroup } from "react-bootstrap";
import { Checkbox } from "@material-ui/core";

interface Props {
  brands: Array<string>;
  setBrands: (state: Array<string>) => void;
}

function StoreFilterByBrand({ brands, setBrands }: Props) {
  const { language } = useContext(LanguageContext);
  const { data, isFetching } = useQuery(
    ["brands"],
    () => ApiInterface().store.getBrands(),
    { retry: 10, retryDelay: 500 }
  );

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    if (
      Boolean(
        brands.find((a) => a === (event.target as HTMLInputElement).value)
      )
    ) {
      let temp = brands.filter((prevState) => {
        return prevState !== (event.target as HTMLInputElement).value;
      });
      setBrands(temp);
    } else {
      setBrands(brands.concat((event.target as HTMLInputElement).value));
    }
  }

  return (
    <Accordion className={styles["accordion"]}>
      <AccordionSummary
        className={styles["accordion__summary"]}
        expandIcon={<ExpandMoreIcon />}
      >
        {{ pt: "Marcas", en: "Brands" }[language]}
      </AccordionSummary>
      <AccordionDetails className={styles["accordion__details"]}>
        <FormControl component="fieldset">
          <FormGroup className={styles["form-group"]}>
            {isFetching ? (
              <Loader />
            ) : data ? (
              data.data.data.nodes.elements.map((brand, index) => {
                return (
                  <FormControlLabel
                    key={index}
                    value={brand.fields.name}
                    control={<Checkbox color="default" onClick={handleClick} />}
                    label={brand.fields.name}
                  />
                );
              })
            ) : null}
          </FormGroup>
        </FormControl>
      </AccordionDetails>
    </Accordion>
  );
}

export default StoreFilterByBrand;
