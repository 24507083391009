import ROUTES from "../../resources/router/ROUTES";

interface Link {
  label: Lang;
  href: string;
  subLinks?: Array<Link>;
}

interface Lang {
  pt: string;
  en: string;
}

const LinksData: Array<Link> = [
  {
    label: {
      pt: "QUEM SOMOS",
      en: "WHO WE ARE",
    },
    href: ROUTES.ABOUT,
    subLinks: [
      {
        label: {
          pt: "SOBRE NÓS",
          en: "ABOUT US",
        },
        href: ROUTES.ABOUT,
      },
      {
        label: {
          pt: "DOCUMENTOS",
          en: "DOCUMENTS",
        },
        href: ROUTES.DOCUMENTS,
      },
    ],
  },
  {
    label: {
      pt: "OFERTA",
      en: "OFFER",
    },
    href: ROUTES.OFFERS,
  },
  {
    label: {
      pt: "PARCERIAS / MARCAS",
      en: "PARTNERS / BRANDS",
    },
    href: ROUTES.PARTNERSHIPS,
  },
  {
    label: {
      pt: "PORTFÓLIO",
      en: "PORTFOLIO",
    },
    href: ROUTES.PORTFOLIO,
  },
  {
    label: {
      pt: "CARREIRAS",
      en: "CAREERS",
    },
    href: ROUTES.CAREERS,
  },
  {
    label: {
      pt: "CONTATOS",
      en: "CONTACTS",
    },
    href: ROUTES.CONTACTS,
  },
  {
    label: {
      pt: "PESQUISA",
      en: "SEARCH",
    },
    href: ROUTES.SEARCH
  },
  {
    label: {
      pt: "LOJA ONLINE",
      en: "ONLINE SHOP",
    },
    href: ROUTES.STORE,
  }
];

export default LinksData;
