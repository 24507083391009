import styles from "./StorePopupContent.module.css";
// Components
import SuccessPopupContent from "../../../Components/Popup/SuccessPopupContent/SuccessPopupContent";
import PrivacyStatement from "../../PrivacyStatement/PrivacyStatement";
// Hooks
import { useForm } from "react-hook-form";
import { useState, useContext } from "react";
// Context
import { LanguageContext } from "../../../Context/LanguageContext";
//Resources
import arrow from "../../../resources/media/icons/store/return_arrow.svg";
import PopupInformation from "../PopupInformation.json";

interface Props {
  setIsDone?: () => void;
  closePopup: () => void;
}

export default function StorePopupContent({ setIsDone, closePopup }: Props) {
  const { language } = useContext(LanguageContext);
  const [isFormCorrect, setIsFormCorrect] = useState<boolean>(false);
  const [showTermsAndConditions, setShowTermsAndConditions] =
    useState<boolean>(false);
  const { register, handleSubmit, errors } = useForm();
  const requiredMessage = PopupInformation.requiredError[language];
  const onSubmit = (data: {}, event: any) => {
    sendMessage(event);
    setIsFormCorrect(true);
  };

  const sendMessage = (event: any) => {
    event.preventDefault();

    // emailjs
    //   .sendForm(
    //     "service_ureu3uf",
    //     "template_hhtetba",
    //     event.target,
    //     "user_o0ECDed0rR5aMZil3obWD"
    //   )
    //   .then(
    //     (result) => {
    //       console.log(result.text);
    //     },
    //     (error) => {
    //       console.log(error.text);
    //     }
    //   );
  };

  return (
    <>
      {isFormCorrect ? (
        <SuccessPopupContent closePopup={() => closePopup()} />
      ) : (
        <div className={styles["form"]}>
          {showTermsAndConditions && (
            <div className={styles["form__privacy-statement"]}>
              <img
                onClick={() => setShowTermsAndConditions(false)}
                alt="arrow"
                src={arrow}
                className={styles["form__return-arrow"]}
              />
              <PrivacyStatement />
            </div>
          )}
          <h2 className={styles["form__title"]}>
            {PopupInformation.storeTitle[language]}
          </h2>
          <p>{PopupInformation.storeSubtitle[language]}</p>
          <div className={styles["form__product-details"]}>
            <div>
              <h6>{PopupInformation.product[language]}</h6>
              <h6 className={styles["form__product-description"]}>
                Modelo: Iphone 11 - 64GB
              </h6>
              <h6 className={styles["form__product-color"]}>
                Cinzento Cideral
              </h6>
            </div>
            <h3 className={styles["form__product-price"]}>599,99€</h3>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <label className={styles["form__input-field"]}>
              {PopupInformation.nameField[language]}
              <input
                name="name"
                ref={register({ required: requiredMessage })}
                placeholder="Lorem Ipusm"
              />
              {errors.name && (
                <h6 className={styles["form__error-message"]}>
                  {errors.name.message}
                </h6>
              )}
            </label>
            <label className={styles["form__input-field"]}>
              {PopupInformation.emailField[language]}
              <input
                name="email"
                ref={register({
                  required: requiredMessage,
                  pattern: {
                    value: /^\S+@\S+$/i,
                    message: requiredMessage,
                  },
                })}
                placeholder="loremipsum@example.com"
              />
              {errors.email && (
                <h6 className={styles["form__error-message"]}>
                  {errors.email.message}
                </h6>
              )}
            </label>
            <label className={styles["form__input-field"]}>
              {PopupInformation.phoneField[language]}
              <input
                name="phone"
                placeholder="0000000000"
                ref={register({ required: requiredMessage })}
              />
              {errors.empresa && <h6>{errors.phone.message}</h6>}
            </label>

            <label className={styles["form__checkbox"]}>
              <input
                type="checkbox"
                name="termsAndConditions"
                ref={register({
                  required: PopupInformation.termsAndConditionsError[language],
                })}
              />
              <h6>
                {PopupInformation.termsAndConditions[language][0]}{" "}
                <span onClick={() => setShowTermsAndConditions(true)}>
                  {PopupInformation.termsAndConditions[language][1]}
                </span>
              </h6>
            </label>
            {errors.termsAndConditions && (
              <h6 className={styles["form__error-message"]}>
                {errors.termsAndConditions.message}
              </h6>
            )}
            <button type="submit">
              {PopupInformation.sendButton[language]}
            </button>
          </form>
        </div>
      )}
    </>
  );
}
