import { backOfficeAPI } from "./../Api";
import { AxiosResponse } from "axios";
import { AreasResponses, ProjectsResponses, ProjectResponses } from "./Types";

export const PortfolioAPI = () => ({
  getAreas: async (
    lang: string
  ): Promise<AxiosResponse<AreasResponses.RootObject>> =>
    new Promise((resolve, reject) => {
      backOfficeAPI.axios
        .post("/graphql", {
          query: `
          {
  node(path: "/portfolio") {
    children(version: published,lang: "${lang}", filter: {schema: {is: folder}}) {
      elements {
        displayName
        ... on folder {
          fields {
            color
          }
        }
        children {
          totalCount
        }
      }
    }
  }
}
`,
        })
        .then((response: AxiosResponse<AreasResponses.RootObject>) => {
          resolve(response);
        });
    }),
  getProjects: async (
    lang: string,
    area: string
  ): Promise<AxiosResponse<ProjectsResponses.RootObject>> =>
    new Promise((resolve, reject) => {
      if (area === "Globaleda") area = "";
      backOfficeAPI.axios
        .post("/graphql", {
          query: `{
            node(path: "/portfolio") {
              children(version: published, lang: "${lang}", filter: {schema: {is: folder}, fields: {folder: {name: {regex: "${area}"}}}}) {
                elements {
                  displayName
                  children {
                    elements {
                      uuid
                      ... on project {
                        fields {
                          title
                          date
                          color
                         verButaoVerProjeto 
                          verData
                          verCliente
                          cliente
                        }
                      }
                    }
                  }
                }
              }
            }
          }`,
        })
        .then((response: AxiosResponse<ProjectsResponses.RootObject>) => {
          resolve(response);
        });
    }),
  getProject: async (
    lang: string,
    uuid: string
  ): Promise<AxiosResponse<ProjectResponses.RootObject>> =>
    new Promise((resolve, reject) => {
      backOfficeAPI.axios
        .post("/graphql", {
          query: `{
              node(uuid: "${uuid}", lang: "${lang}") {
    uuid
    ... on project {
      fields {
        area
        cliente
        color
        date
        description
        summary
        title
        link
        verData
        verCliente
        verTipo
        offer {
          parent {
            displayName
          }
          displayName
        }
        gallery {
          uuid
          ... on gallery {
             fields{
              type
              name
            }
          }
        }
      }
    }
  }
            }`,
        })
        .then((response: AxiosResponse<ProjectResponses.RootObject>) => {
          resolve(response);
        });
    }),
});
