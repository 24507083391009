import styles from "./PortfolioItem.module.css";
import cx from "classnames";
// Components
import Loader from "../../../Components/Loader/Loader";
import Button from "../../../Components/Button/Button";
import MediaDisplay from "../../../Components/MediaDisplay/MediaDisplay";
import StackGrid from "react-stack-grid";
// Context
// Hooks
import { useRouteMatch } from "react-router-dom";
import { useQuery } from "react-query";
import { useContext, useEffect, useState } from "react";
import useColumnWidth from "../../../Hooks/useColumnWidth";
// Pages
// Resources
import { LanguageContext } from "../../../Context/LanguageContext";
import { ApiInterface, APP_BACK_OFFICE_URL } from "../../../resources/api/Api";
import { ProjectResponses } from "../../../resources/api/Portfolio/Types";
import play_icon from "../../../resources/media/images/homepage/play.svg";

function PortfolioItem() {
  const { language } = useContext(LanguageContext);
  const { url } = useRouteMatch();
  const uuid = url.split("/")[2];
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const columnWidth = useColumnWidth();
  const { data, isFetching } = useQuery(
    ["product", uuid, language],
    () => ApiInterface().portfolio.getProject(language, uuid),
    { retry: 10, retryDelay: 500 }
  );

  useEffect(() => {
    if (showPopup) {
      document.querySelector("html")!.style.overflow = "hidden";
    } else {
      document.querySelector("html")!.style.overflow = "visible";
    }
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (isFetching) {
    return <Loader />;
  }

  if (!data) {
    return null;
  }

  const project = data.data.data;

  let year = new Date(project.node.fields.date).getFullYear();

  return (
    <div className={styles["project"]}>
      {showPopup && (
        <MediaDisplay
          imageIndex={activeIndex}
          mediaContent={project.node.fields.gallery}
          onClick={() => setShowPopup(false)}
        />
      )}

      <div className={styles["project__grid"]}>
        <div
          className={cx(
            styles["project__image-border"],
            styles[project.node.fields.color]
          )}
        >
          <img
            // width="100%"
            // height="100%"
            alt="projectImage"
            src={`${APP_BACK_OFFICE_URL}nodes/${project.node.uuid}/binary/image`}
            className={styles["project__image"]}
          />
        </div>
        <div className={styles["project__title"]}>
          <h1>{project.node.fields.title}</h1>
          <div
            className={cx(
              styles["project__line"],
              styles[project.node.fields.color]
            )}
          ></div>
          <h6 className={styles["project__service"]}>
            {
              {
                pt: "Área",
                en: "Field",
              }[language]
            }
          </h6>
          <h6 className={styles["project__service-description"]}>
            {project.node.fields.area}
          </h6>
        </div>
        <div className={styles["project__content"]}>
          <div className={styles["project__short-description"]}>
            <dd
              className={styles["project__short-description-text"]}
              dangerouslySetInnerHTML={{ __html: project.node.fields.summary }}
            />
          </div>

          {project.node.fields.verData ||
          project.node.fields.verCliente ||
          project.node.fields.verTipo ? (
            <div className={styles["project__info"]}>
              {project.node.fields.verData ? (
                <ProjectInfo
                  label={
                    {
                      pt: "Ano",
                      en: "Year",
                    }[language]
                  }
                  text={year.toString()}
                />
              ) : (
                <></>
              )}

              {project.node.fields.verCliente ? (
                <ProjectInfo
                  label={
                    {
                      pt: "Cliente",
                      en: "Client",
                    }[language]
                  }
                  text={project.node.fields.cliente}
                />
              ) : (
                <></>
              )}
              {project.node.fields.verTipo ? (
                <ProjectInfo
                  label={project.node.fields.offer?.parent.displayName}
                  text={project.node.fields.offer?.displayName}
                />
              ) : (
                <></>
              )}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      {project.node.fields.description && (
        <div className={styles["project__long-description"]}>
          <dd
            dangerouslySetInnerHTML={{
              __html: project.node.fields.description,
            }}
          />
        </div>
      )}
      <StackGrid
        columnWidth={columnWidth}
        gutterWidth={20}
        gutterHeight={20}
        monitorImagesLoaded={true}
        className={styles["grid"]}
      >
        {project.node.fields.gallery.map(
          (media: ProjectResponses.Gallery, index: number) => {
            return (
              <div
                key={index}
                className={cx(
                  styles["image__container"],
                  // !project.node.fields.description && styles["spacer"]
                )}
              >
                {media.fields.type === "video" ? (
                  <>
                    <video
                      className={styles["image__content"]}
                      id="video"
                      onClick={() => {
                        setShowPopup(!showPopup);
                        setActiveIndex(index);
                      }}
                      preload={"auto"}
                    >
                      <source
                        src={`${APP_BACK_OFFICE_URL}nodes/${media.uuid}/binary/content#t=0.001`}
                        type="video/mp4"
                      />
                    </video>
                    <img
                      className={styles["image__play-icon"]}
                      src={play_icon}
                      alt="video"
                    />
                  </>
                ) : (
                  <img
                    className={styles["image__content"]}
                    alt="image1"
                    src={`${APP_BACK_OFFICE_URL}nodes/${media.uuid}/binary/content`}
                    onClick={() => {
                      setShowPopup(!showPopup);
                      setActiveIndex(index);
                    }}
                  />
                )}
              </div>
            );
          }
        )}
      </StackGrid>
      {project.node.fields.link && (
        <div className={styles["project__button-container"]}>
          <a href={project.node.fields.link} target="_blank" rel="noreferrer">
            <Button
              text={{ pt: "VER PROJETO", en: "SEE PROJECT" }[language]}
              color="button--red"
              width="20em"
              showArrow={false}
            />
          </a>
        </div>
      )}
    </div>
  );
}

interface Props {
  label: string | undefined;
  text: string | undefined;
}

function ProjectInfo({ label, text }: Props) {
  return (
    <div>
      <h6 className={styles["project__label"]}>{label}</h6>
      <h6 className={styles["project__info-text"]}>{text}</h6>
    </div>
  );
}

export default PortfolioItem;
