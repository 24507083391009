import axios from "axios";
import React, { createRef, useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router";
import Loader from "../../../Components/Loader/Loader";
import { LanguageContext } from "../../../Context/LanguageContext";
import { ApiInterface, APP_BACK_OFFICE_URL } from "../../../resources/api/Api";
import styles from "./SearchPage.module.css";
// Components
// Context
// Hooks
// Pages
// Resources
import pdf from "../../../resources/media/icons/documents/pdf.svg";
import SearchField from "../../../Components/SearchField/SearchField";
import SearchFilter from "../SearchFilter/SearchFilter";

export interface Filter {
  document: boolean;
  offer: boolean;
  partner: boolean;
  project: boolean;
  contact: boolean;
}
export default function SearchPage() {
  const { searchText } = useParams<{ searchText: string }>();
  const { language } = useContext(LanguageContext);
  const [filter, setFilter] = useState<Filter>({
    document: false,
    offer: false,
    partner: false,
    project: false,
    contact: false,
  });

  const { data, isFetching, refetch } = useQuery(["search", language], () => {
    if (searchText) return ApiInterface().search.search(decodeURI(searchText), language.toString());
    else return undefined;
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    refetch();
  }, [searchText]);

  async function openPDF(uuid: string) {
    var response = await axios(
      `${APP_BACK_OFFICE_URL}nodes/${uuid}/binary/document`,
      {
        method: "GET",
        responseType: "blob",
      }
    );
    var blobUrl = URL.createObjectURL(
      new Blob([response.data], { type: "application/pdf" })
    );
    window.open(blobUrl, "_blank");
  }

  function generateSum(html: string) {
    var span = document.createElement("span");
    html = html.replaceAll("&nbsp;", " ")
    span.innerHTML = html;
    return span.textContent?.substring(0, 140).concat("...");
  }

  if (isFetching) return <Loader />;

  const results = data?.data.data.map((result, index) => {
    switch (result.schema.name) {
      case "document":
        if (filter.document || !Object.values(filter).includes(true))
          return (
            <div key={index} className={styles["result_div"]}>
              <a
                className={styles["result_link"]}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => openPDF(result.uuid)}
              >
                {result.fields.name}
              </a>
              <span className={styles["result_type"]}>
                {{ pt: "Documentos", en: "Documents" }[language]}
              </span>
            </div>
          );
        break;
      case "offer":
        if (filter.offer || !Object.values(filter).includes(true))
          return (
            <div key={index} className={styles["result_div"]}>
              <a
                className={styles["result_link"]}
                href={`/oferta/${result.uuid}`}
              >
                {result.fields.name}
              </a>
              <span className={styles["result_type"]}>
                {{ pt: "Ofertas", en: "Offers" }[language]}
              </span>
              <span className={styles["result_summary"]}>
                {generateSum(result.fields.text!)}
              </span>
            </div>
          );
        break;
      case "partner":
        if (filter.partner || !Object.values(filter).includes(true))
          return (
            <div key={index} className={styles["result_div"]}>
              <a
                className={styles["result_link"]}
                href={`/parcerias/${result.uuid}`}
              >
                {result.fields.name}
              </a>
              <span className={styles["result_type"]}>
                {{ pt: "Parcerias", en: "Partnerships" }[language]}
              </span>
              <span className={styles["result_summary"]}>
                {generateSum(result.fields.introduction)}
              </span>
            </div>
          );
        break;
      case "project":
        if (filter.project || !Object.values(filter).includes(true))
          return (
            <div key={index} className={styles["result_div"]}>
              <a
                className={styles["result_link"]}
                href={`/portfolio/${result.uuid}`}
              >
                {result.fields.title}
              </a>
              <span className={styles["result_type"]}>
                {{ pt: "Projectos", en: "Projects" }[language]}
              </span>
              <span className={styles["result_summary"]}>
                {generateSum(result.fields.summary)}
              </span>
            </div>
          );
        break;
      case "contact":
        if (filter.contact || !Object.values(filter).includes(true))
          return (
            <div key={index} className={styles["result_div"]}>
              <a
                className={styles["result_link"]}
                href={result.fields.googleMaps}
              >
                {result.fields.name}
              </a>
              <span className={styles["result_type"]}>
                {{ pt: "Contatos", en: "Contacts" }[language]}
              </span>
              <div className={styles["result_summary"]}>
                <p>{result.fields.address}</p>
                {result.fields.phoneNumber && (
                  <p>{result.fields.phoneNumber}</p>
                )}
                {result.fields.email && <p>{result.fields.email}</p>}
              </div>
            </div>
          );
        break;
      default:
        break;
    }
  });

  const noResults =
    (results === undefined || results.every((el) => el === undefined)) &&
    searchText;

  return (
    <div className={styles["cols"]}>
      <SearchFilter setFilter={setFilter} filter={filter} />
      <div>
        <SearchField initialSearchText={searchText} />
        <div className={styles["search_results"]}>{results}</div>
        {noResults && (
          <div className={styles["no_results"]}>
            <span>
              {
                {
                  pt: "Não foram encontrados resultados.",
                  en: "No results have been found.",
                }[language]
              }
            </span>
            <span>
              {
                { pt: "Por favor tente novamente.", en: "Please try again." }[
                  language
                ]
              }
            </span>
          </div>
        )}
      </div>
    </div>
  );
}
