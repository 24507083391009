import styles from "./Page.module.css";
import cx from "classnames";
// Components
// Context
// Hooks
// Pages
// Resources

interface Props {
  children: React.ReactNode;
}

export default function Page({ children }: Props) {
  return (
    <section className={cx("portal-container", styles["section"])}>
      {children}
    </section>
  );
}
