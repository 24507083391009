import styles from "./DropdownMenu.module.css";
import cx from "classnames";
// Components
// Context
// Hooks
import { useState } from "react";
// Pages
// Resources
import arrow_down from "../../resources/media/icons/header/arrow_down.svg";

interface DropDownMenuProps {
  options: Array<string>;
  onClick: (index: number) => void;
  className?: string;
}

export default function DropDownMenu({ options, onClick, className }: DropDownMenuProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [currentOption, setCurrentOption] = useState(options[0]);
  return (
    <div  className={cx(className?  className : "", styles["dropdown-menu"])}>
      <button
        className={styles["dropdown-menu__button"]}
        onClick={() => setIsOpen(!isOpen)}
      >
        {currentOption}
        <img
          alt="arrow-down"
          src={arrow_down}
          className={cx(
            styles["dropdown-menu__arrow-icon"],
            isOpen && styles["dropdown-menu__arrow-icon--inverse"]
          )}
        ></img>
      </button>
      <ul
        className={cx(
          styles["dropdown-menu__options"],
          styles[isOpen ? "" : "dropdown-menu__options--hidden"],
          isOpen && styles["dropdown-menu__options--border-top"]
        )}
      >
        {options.map((option: string, index: number) => {
          return (
            <li
              key={index}
              onClick={() => {
                setIsOpen(!isOpen);
                setCurrentOption(option);
                onClick(index);
              }}
            >
              {option}
            </li>
          );
        })}
      </ul>
    </div>
  );
}
