import styles from "./InstitutionalDocuments.module.css";
// Components
import Accordion from "../Accordion/Accordion";
import AccordionItem from "../Accordion/AccordionItem/AccordionItem";
import { ACCORDION } from "../../resources/typing/enumerates";
// Context
// Hooks
import { useContext, useEffect } from "react";
// Pages
// Resources

import pdf from "../../resources/media/icons/documents/pdf.svg";
import { Documents } from "../../resources/api/Documents/Types";
import { APP_BACK_OFFICE_URL } from "../../resources/api/Api";
import { BluetoothSearchingOutlined, MenuSharp } from "@material-ui/icons";
import axios from "axios";
import { LanguageContext } from "../../Context/LanguageContext";
import moment from "moment";

interface InstitutionalDocumentsProps {
  institutionalDocuments: Documents.Element[];
}

export default function InstitutionalDocuments({
  institutionalDocuments,
}: InstitutionalDocumentsProps) {
  const { language } = useContext(LanguageContext);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  async function openPDF(uuid: string) {
    var response = await axios(
      `${APP_BACK_OFFICE_URL}nodes/${uuid}/binary/document?lang=${language}`,
      {
        method: "GET",
        responseType: "blob",
      }
    );
    if (response.status == 404) {
      var response = await axios(
        `${APP_BACK_OFFICE_URL}nodes/${uuid}/binary/document`,
        {
          method: "GET",
          responseType: "blob",
        }
      );
    }
    var blobUrl = URL.createObjectURL(
      new Blob([response.data], { type: "application/pdf" })
    );
    window.open(blobUrl, "_self");
  }

  const orderedDocuments = institutionalDocuments.sort((a, b) => {
    if (moment(a.created) < moment(b.created)) {
      return 1;
    }
    if (moment(a.created) > moment(b.created)) {
      return -1;
    }
    return 0;
  });

  function uuid() {
    var dt = new Date().getTime();
    var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
      }
    );
    return uuid;
  }

  function accordionGetName(
    accordion: Documents.Element,
    language: string
  ): string {
    return accordion.displayName;
  }

  function sortedSubDocs(elements: Documents.Element3[]) {
    return elements.sort((a, b) => {
      if (moment(a.created) < moment(b.created)) {
        return 1;
      }
      if (moment(a.created) > moment(b.created)) {
        return -1;
      }
      return 0;
    });
  }

  function sortedDocs(elements: Documents.Element2[]) {
    return elements.sort((a, b) => {
      if (moment(a.created) < moment(b.created)) {
        return 1;
      }
      if (moment(a.created) > moment(b.created)) {
        return -1;
      }
      return 0;
    });
  }

  return (
    <div className={styles["documents__accordion-container"]}>
      <Accordion type={ACCORDION.SIMPLE} key={uuid()} allCollapsed={false}>
        {orderedDocuments.map((accordion, index) => {
          if (!(accordion.fields.slug == "certificacoes" || accordion.fields.slug == "certificacoes-EN" || accordion.fields.slug == "certifications"))
            return (
              <AccordionItem
                key={uuid()}
                title={accordionGetName(accordion, language)}
                isCollapsed={true}
                titleSize={"var(--font-size-2)"} 
              >
                {sortedDocs(accordion.children.elements).map((document, index) => {
                  if (document.__typename == "document")
                    return (
                      <div
                        key={uuid()}
                        className={styles["documents__accordion-item"]}
                      >
                        <img
                          src={pdf}
                          alt="pdf"
                          className={styles["documents__icon"]}
                        />
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={() => openPDF(document.uuid)}
                        >
                          {document.fields.name}
                        </a>
                      </div>
                    );
                  if (
                    document.__typename == "folder" &&
                    document.fields.slug != "certificacoes"
                  )
                    return (
                      <div
                        className={styles["documents__accordion-container-sub"]}
                      >
                        <Accordion type={ACCORDION.SIMPLE} key={uuid()} allCollapsed={false}>
                          <AccordionItem
                            key={uuid()}
                            title={document.displayName}
                            titleSize={"var(--font-size-2)"}
                          >
                            {sortedSubDocs(document.children.elements).map(
                              (subDocument, subindex) => {
                                return (
                                  <div
                                    key={uuid()}
                                    className={
                                      styles["documents__accordion-item"]
                                    }
                                  >
                                    <img
                                      src={pdf}
                                      alt="pdf"
                                      className={styles["documents__icon"]}
                                    />
                                    <a
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      onClick={() => openPDF(subDocument.uuid)}
                                    >
                                      {subDocument.fields.name}
                                    </a>
                                  </div>
                                );
                              }
                            )}
                          </AccordionItem>
                          <div></div>
                        </Accordion>
                      </div>
                    );
                  else return <></>;
                })}
              </AccordionItem>
            );
          else return <></>;
        })}
      </Accordion>
    </div>
  );
}
