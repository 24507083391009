import styles from "./Certifications.module.css";
import { Documents } from "../../resources/api/Documents/Types";
import { useContext, useEffect } from "react";
import { LanguageContext } from "../../Context/LanguageContext";
import { APP_BACK_OFFICE_URL } from "../../resources/api/Api";
import axios from "axios";
import pdf from "../../resources/media/icons/documents/pdf.svg";

interface CertificationProps {
  certifications: Documents.Element[];
}

export default function Certifications({ certifications }: CertificationProps) {
  const { language } = useContext(LanguageContext);
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  async function openPDF(uuid: string) {
    var response = await axios(
      `${APP_BACK_OFFICE_URL}nodes/${uuid}/binary/document?lang=${language}`,
      {
        method: "GET",
        responseType: "blob",
      }
    );
    if (response.status == 404) {
      var response = await axios(
        `${APP_BACK_OFFICE_URL}nodes/${uuid}/binary/document`,
        {
          method: "GET",
          responseType: "blob",
        }
      );
    }
    var blobUrl = URL.createObjectURL(
      new Blob([response.data], { type: "application/pdf" })
    );
    window.open(blobUrl, "_self");
  }

  const certs = certifications.find(
    (element) =>
      element.fields.slug == "certificacoes" ||
      element.fields.slug == "certificacoes-EN" ||
      element.fields.slug == "certifications"
  )
    ? certifications.find(
        (element) =>
          element.fields.slug == "certificacoes" ||
          element.fields.slug == "certificacoes-EN" ||
          element.fields.slug == "certifications"
      )!.children.elements
    : [];

  return (
    <div className={styles["documents-container"]}>
      {certs.map((document, index) => {
        return (
          <div key={index} className={styles["documents__accordion-item"]}>
            <img src={pdf} alt="pdf" className={styles["documents__icon"]} />
            <a
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => openPDF(document.uuid)}
            >
              {document.fields.name}
            </a>
          </div>
        );
      })}
    </div>
  );
}
