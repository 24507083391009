import React, { useState } from "react";
// Components
// Context
// Pages
//Resources
import { ACCORDION } from "../../resources/typing/enumerates";

interface Props {
  children: React.ReactElement[];
  type: ACCORDION;
  allCollapsed: boolean | null;
}

export default function Accordion({ children, type, allCollapsed }: Props) {
  const [bindIndex, setBindIndex] = useState(0);

  const changeItem = (itemIndex: number) => {
    if (itemIndex !== bindIndex) setBindIndex(itemIndex);
    if (itemIndex === bindIndex) setBindIndex(-1);
  };

  const childWithProps = React.Children.map(
    children,
    (child: React.ReactElement, index: number) => {
      return React.cloneElement(child, {
        index: index,
        isCollapsed: (allCollapsed == null ? bindIndex === index : allCollapsed),
        handleClick: () => changeItem(index),
        type: type,
      });
    }
  );
  return <React.Fragment>{childWithProps}</React.Fragment>;
}
