import React, { useContext, useEffect } from "react";
import styles from "./Careers.module.css";
import Button from "../../Components/Button/Button";
import { LanguageContext } from "../../Context/LanguageContext";
// Components
// Context
// Hooks
// Pages
// Resources
import worker_image from "../../resources/media/images/careers/worker.jpg";
import DATA from "./data.json";
export default function Careers() {
  const { language } = useContext(LanguageContext);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles["careers"]}>
      <img className={styles["careers__image"]} src={worker_image} alt="" />
      <div className={styles["careers__text"]}>
        <h1 className={styles["careers__question"]}>{DATA.title[language]}</h1>
        <h6 className={styles["careers__info"]}>{DATA.text[language]}</h6>
        <div className={styles["careers__button"]}>
          <Button
            text={DATA.buttonText[language]}
            color="button--red"
            showArrow={false}
            href="career012.successfactors.eu/career?company=edaelectriP&site=VjItbUVvaVFkMnZMYXh2TmxuekE5Wmx3dz09"
          />
        </div>
      </div>
    </div>
  );
}
