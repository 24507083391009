import React from "react";
import styles from "./Loader.module.css";
// Components
// Context
// Hooks
// Pages
// Resources
import Spinner from "../../resources/media/images/spinner-01-01.png";

function Loader() {
  return (
    <div className={styles["spinner"]}>
      <img src={Spinner} alt="spinner" />
    </div>
  );
}

export default Loader;
