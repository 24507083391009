import React, { useContext, useState } from "react";
import styles from "./SearchField.module.css";
// Components
// Context
// Hooks
// Pages
// Resources
import search_icon from "../../resources/media/icons/header/search.svg";
import { IconButton } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { LanguageContext } from "../../Context/LanguageContext";

interface FieldProps {
  initialSearchText: string;
}
function SearchField({ initialSearchText }: FieldProps) {
  const [searchText, setSearchText] = useState(initialSearchText);
  const history = useHistory();
  const { language } = useContext(LanguageContext)

  function onKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === "Enter") {
      history.push("/pesquisa/" + encodeURIComponent(searchText));
    }
  }

  return (
    <div className={styles["search-bar"]}>
      <input
        autoFocus={true}
        className={styles["search-bar__input-line"]}
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        onKeyDown={onKeyDown}
        placeholder={
            { pt: "O que procura?", en: "What do you search for?" }[language]
          }
      ></input>
      <img
        src={search_icon}
        width="1.5em"
        height="100%"
        alt="search icon"
        className={styles["search-bar__search-icon"]}
      />
    </div>
  );
}

export default SearchField;