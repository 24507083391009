import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
} from "@material-ui/core";
import styles from "./SearchFilter.module.css";
import React, { useContext } from "react";
import { LanguageContext } from "../../../Context/LanguageContext";
import { Filter } from "../SearchPage/SearchPage";

interface Categories {
  name: string;
  displayName: string;
}
interface Props {
  filter: Filter;
  setFilter: React.Dispatch<React.SetStateAction<Filter>>;
}
function SearchFilter({ filter, setFilter }: Props) {
  const { language } = useContext(LanguageContext);
  const categories: Categories[] = [
    {
      name: "document",
      displayName: { pt: "Documentos", en: "Documents" }[language],
    },
    {
      name: "offer",
      displayName: { pt: "Ofertas", en: "Offers" }[language],
    },
    {
      name: "partner",
      displayName: { pt: "Parcerias", en: "Partnerships" }[language],
    },
    {
      name: "project",
      displayName: { pt: "Projetos", en: "Projects" }[language],
    },
    {
      name: "contact",
      displayName: { pt: "Contatos", en: "Contacts" }[language],
    },
  ];

  function handleClick(e: boolean, c: string) {
    let f = JSON.parse(JSON.stringify(filter));
    console.log(e)
    f[c as keyof Filter] = !e;
    setFilter((s) => f);
  }


  return (
    <div>
      <span className={styles["filter_header"]}>{{ pt: "Filtrar por", en: "Filter by" }[language]}</span>

      <FormControl component="fieldset">
        <FormGroup className={styles["form-group"]}>
          {categories.map((cat, index) => 
              <FormControlLabel
                key={index}
                value={cat.name}
                className={styles["checkbox"]}
                control={<Checkbox color="default" onClick={(e) => handleClick(filter[cat.name as keyof Filter], cat.name)}
                checked={filter[cat.name as keyof Filter]} />}
                label={cat.displayName}
              />
          )}
        </FormGroup>
      </FormControl>
    </div>
  );
}

export default SearchFilter;
