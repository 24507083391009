import { useContext, useEffect, useState } from "react";
import cx from "classnames";
import styles from "./Portfolio.module.css";
// Components
import Loader from "../../Components/Loader/Loader";
import StackGrid from "react-stack-grid";
// Context
import { LanguageContext } from "../../Context/LanguageContext";
import PortfolioFilter from "./PortfolioFilter/PortfolioFilter";
// Hooks
import { useQuery } from "react-query";
import useColumnWidth from "../../Hooks/useColumnWidth";
// Pages
// Resources
import { ApiInterface } from "../../resources/api/Api";
import { BsArrowRight } from "react-icons/bs";
import { Link } from "react-router-dom";
import { APP_BACK_OFFICE_URL } from "../../resources/api/Api";

function Portfolio() {
  const { language } = useContext(LanguageContext);
  const [area, setArea] = useState<string>("");
  const columnWidth = useColumnWidth();
  const { data, isFetching } = useQuery(
    ["portfolio", language, area],
    () => ApiInterface().portfolio.getProjects(language, area),
    {
      retry: 10,
      retryDelay: 500,
    }
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles["portfolio"]}>
      <div className={styles["portfolio__filter"]}>
        <PortfolioFilter area={area} setArea={setArea} />
      </div>
      {isFetching ? (
        <Loader />
      ) : !data ? null : (
        <div className={styles["portfolio__grid"]}>
          <StackGrid columnWidth={columnWidth} monitorImagesLoaded={true}>
            {data.data.data.node.children.elements.map((folder) => {
              return folder.children.elements.map((project, index) => {
                let year = new Date(project.fields.date).getFullYear();
                return (
                  <Link key={index} to={`/portfolio/${project.uuid}`}>
                    <div className={styles["project"]}>
                      <div className={styles["project__image-container"]}>
                        <img
                          className={styles["project__image"]}
                          src={`${APP_BACK_OFFICE_URL}nodes/${project.uuid}/binary/image`}
                          alt={project.fields.title}
                        />
                        {project.fields.verButaoVerProjeto && (
                          <div className={styles["project__view-more"]}>
                            <h6>
                              {
                                { pt: "Ver Projeto", en: "See Project" }[
                                  language
                                ]
                              }
                            </h6>
                            <BsArrowRight />
                          </div>
                        )}
                      </div>
                      <div
                        className={cx(
                          styles["project__text"],
                          styles[project.fields.color]
                        )}
                      >
                        {project.fields.verData && (
                          <h6 className={styles["project__year"]}>{year}</h6>
                        )}
                        {project.fields.verCliente && (
                          <h6 className={styles["project__year"]}>
                            {project.fields.cliente}
                          </h6>
                        )}
                        <h6 className={styles["project__name"]}>
                          {project.fields.title}
                        </h6>
                      </div>
                    </div>
                  </Link>
                );
              });
            })}
          </StackGrid>
        </div>
      )}
    </div>
  );
}

export default Portfolio;
