// Example of an enumerate
export enum STORE_CATEGORIES {
  PHONES = "PHONES",
  COMPUTERS = "COMPUTERS",
  TABLETS = "TABLETS",
  SMARTWATCHES = "SMARTWATCHES",
  CAMERAS = "CAMERAS",
  EARPHONES = "EARPHONES",
  TROTINETES = "TROTINETES",
}

export enum ISLANDS {
  SAOMIGUEL = "SÃO MIGUEL",
  PICO = "PICO",
  TERCEIRA = "TERCEIRA",
  SAOJORGE = "SÃO JORGE",
  FAIAL = "FAIAL",
  GRACIOSA = "GRACIOSA",
  SANTAMARIA = "SANTA MARIA",
  CORVO = "CORVO",
  FLORES = "FLORES",
}

export enum ACCORDION {
  SIMPLE = "SIMPLE",
  CUSTOM = "CUSTOM",
}

export enum PROJECT_CATEGORIES_PT {
  GENERAL = "Globaleda",
  COMMUNICATIONS = "Telecomunicações",
  COMMERCIAL = "Gestão Commercial",
  IT = "Sistemas de Informação",
}
export enum PROJECT_CATEGORIES_EN {
  GENERAL = "Globaleda",
  COMMUNICATIONS = "Telecommunications",
  COMMERCIAL = "Commercial Management",
  IT = "Information Technologies",
}
