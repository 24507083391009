import { useLayoutEffect } from "react";

const useLockBodyScroll = () => {
  useLayoutEffect(() => {
    document.querySelector("html")!.style.overflow = "hidden";

    return () => {
      document.querySelector("html")!.style.overflow = "visible";
    };
  }, []);
};

export default useLockBodyScroll;
