import React, { useContext } from "react";
import styles from "./StoreGridItem.module.css";
// Components
// Context
import { LanguageContext } from "../../../Context/LanguageContext";
// Hooks
// Pages
// Resources
import { StoreResponses } from "../../../resources/api/Store/Types";
import { APP_BACK_OFFICE_URL } from "../../../resources/api/Api";

interface Props {
  product: StoreResponses.Element2;
}

function StoreGridItem({ product }: Props) {
  const { language } = useContext(LanguageContext);

  return (
    <div className={styles["product"]}>
      <div className={styles["product__image-container"]}>
        {product.fields.prevPrice !== 0 && product.fields.prevPrice !== null && (
          <div className={styles["product__discount"]}>
            <h6>
              {-(product.fields.prevPrice - product.fields.price).toFixed() +
                "€"}
            </h6>
          </div>
        )}
        <img
          className={styles["product__image"]}
          width="100%"
          height="100%"
          src={`${APP_BACK_OFFICE_URL}nodes/${product.uuid}/binary/image`}
          alt={`product`}
        />
      </div>
      <h5 className={styles["product__name"]}>{product.fields.name}</h5>
      <div className={styles["product__prices"]}>
        {product.fields.prevPrice ? (
          <>
            <div className={styles["product__price-now"]}>
              <h6>{{ pt: "Agora", en: "Now" }[language]}</h6>
              <h6>{product.fields.price + "€"}</h6>
            </div>

            <div className={styles["product__price-before"]}>
              <h6>{{ pt: "Antes", en: "Before" }[language]}</h6>
              <h6 className={styles["product__value-before"]}>
                {product.fields.prevPrice + "€"}
              </h6>
            </div>
          </>
        ) : (
          <h6 className={styles["product__normal-price"]}>
            {product.fields.price + "€"}
          </h6>
        )}
      </div>
    </div>
  );
}

export default StoreGridItem;
