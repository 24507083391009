import React, { useEffect, useState } from "react";
import styles from "./App.module.css";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
// Components
// Pages
// Context
import { LanguageProvider } from "../../Context/LanguageContext";
// Hooks
// Pages
// Resources
import ROUTES from "../../resources/router/ROUTES";
import Toolbar from "../Toolbar/Toolbar";
import SideDrawer from "../SideDrawer/SideDrawer";
import Page from "../Page/Page";
import Store from "../../Pages/Store/Store";
import Offers from "../../Pages/Offers/Offers";
import Offer from "../../Pages/Offers/Offer/Offer";
import Careers from "../../Pages/Carreers/Careers";
import Portfolio from "../../Pages/Portfolio/Portfolio";
import Contacts from "../../Pages/Contacts/Contacts";
import Homepage from "../../Pages/Homepage/Homepage";
import About from "../../Pages/About/About";
import Documents from "../../Pages/About/Documents/Documents";
import Partnerships from "../../Pages/Partnerships/Partnerships";
import NotFound from "../../Pages/NotFound/NotFound";
import StoreItem from "../../Pages/Store/StoreItem/StoreItem";
import PortfolioItem from "../../Pages/Portfolio/PortfolioItem/PortfolioItem";
import PartnerPage from "../../Pages/Partnerships/PartnerPage/PartnerPage";
import SearchPage from "../../Pages/Search/SearchPage/SearchPage";
import AnniversaryPage from "../../Pages/Anniversary/AnniversaryPage";

function App() {
  const [sideDrawerOpen, setDrawerOpen] = useState<boolean>(false);
  const location = useLocation();

  useEffect(() => {
    setDrawerOpen(false);
  }, [location]);

  const drawerToggleClickHandler = () => {
    setDrawerOpen((prevState) => !prevState);
  };

  /*   const backdropClickHandler = () => {
    setDrawerOpen(false);
  }; */
  /* 
  let backDrop;

  if (sideDrawerOpen) {
    backDrop = <Backdrop click={backdropClickHandler} />;
  } */

  return (
    <LanguageProvider>
      <div className={styles["app"]}>
        <Toolbar
          drawerClickHandler={drawerToggleClickHandler}
          isOpen={sideDrawerOpen}
        />
        <SideDrawer show={sideDrawerOpen} />
        {/*{backDrop}*/}
        <Switch>
          <Route exact path={ROUTES.STORE}>
            <Page>
              <Store />
            </Page>
          </Route>

          <Route exact path={ROUTES.STORE_ITEM()}>
            <Page>
              <StoreItem />
            </Page>
          </Route>

          <Route exact path={ROUTES.OFFERS}>
            <Page>
              <Offers />
            </Page>
          </Route>
          <Route exact path={ROUTES.OFFER()}>
            <Page>
              <Offer />
            </Page>
          </Route>

          <Route exact path={ROUTES.CAREERS}>
            <Page>
              <Careers />
            </Page>
          </Route>

          <Route exact path={ROUTES.PORTFOLIO}>
            <Page>
              <Portfolio />
            </Page>
          </Route>

          <Route exact path={ROUTES.PORTFOLIO_PAGE()}>
            <Page>
              <PortfolioItem />
            </Page>
          </Route>

          <Route exact path={ROUTES.CONTACTS}>
            <Page>
              <Contacts />
            </Page>
          </Route>

          <Route exact path={ROUTES.INDEX}>
            <Homepage />
          </Route>

          <Route exact path={ROUTES.ABOUT}>
            <Page>
              <About />
            </Page>
          </Route>

          <Route exact path={ROUTES.DOCUMENTS}>
            <Page>
              <Documents />
            </Page>
          </Route>

          <Route exact path={ROUTES.PARTNERSHIPS}>
            <Page>
              <Partnerships />
            </Page>
          </Route>

          <Route exact path={ROUTES.PARTNER_PAGE()}>
            <Page>
              <PartnerPage />
            </Page>
          </Route>

          <Route exact path={ROUTES.SEARCH}>
            <Page>
              <SearchPage />
            </Page>
          </Route>
          <Route exact path={ROUTES.SEARCH_TEXT()}>
            <Page>
              <SearchPage />
            </Page>
          </Route>

          <Route exact path={ROUTES.ANNIVERSARY}>
            <Page>
              <AnniversaryPage />
            </Page>
          </Route>


          <Route exact path={ROUTES.NOT_FOUND}>
            <Page>
              <NotFound />
            </Page>
          </Route>

          <Route exact path="/">
            <Redirect to={ROUTES.NOT_FOUND} />
          </Route>
        </Switch>
      </div>
    </LanguageProvider>
  );
}

export default App;
