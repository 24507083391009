import styles from "./Offers.module.css";
import { Link } from "react-router-dom";
import cx from "classnames";
// Components
import Button from "../../Components/Button/Button";
import ContactsPopupContent from "../../Components/Popup/ContactsPopupContent/ContactsPopupContent";
import Loader from "../../Components/Loader/Loader";
import Popup from "../../Components/Popup/Popup";
// Context
import { LanguageContext } from "../../Context/LanguageContext";
// Hooks
import { useContext, useState, useEffect } from "react";
// Pages
// Resources
import { ApiInterface } from "../../resources/api/Api";
import arrow from "../../resources/media/icons/others/arrow.svg";
import { solutions } from "./offersData";
import { useQuery } from "react-query";
import moment from "moment";

export default function Offers() {
  const { language } = useContext(LanguageContext);
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const { data, isFetching } = useQuery(["offers", language], () =>
    ApiInterface().offers.getOffers(language)
  );

  const { data: dataIntro, isFetching: isFetchingIntro } = useQuery(
    ["offersIntro", language],
    () => ApiInterface().offers.getOffersIntro(language)
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handlePopup = (): void => {
    setIsPopupOpen(!isPopupOpen);
  };

  function getInnerHTML(htmlString: string | undefined) {
    if (htmlString) {
      return { __html: htmlString };
    } else {
      return { __html: "" };
    }
  }

  return (
    <div>
      {isPopupOpen && (
        <Popup closePopup={handlePopup}>
          <ContactsPopupContent closePopup={handlePopup} />
        </Popup>
      )}
      <section className={cx(styles["section"], styles["first-section"])}>
        <div className={styles["first-section__header-text"]}>
          <h2>{dataIntro?.data.data.node.children.elements[0].fields.title}</h2>
          <div
            dangerouslySetInnerHTML={getInnerHTML(
              dataIntro?.data.data.node.children.elements[0].fields.body
            )}
          ></div>
        </div>
        <div className={styles["first-section__cards"]}>
          <div
            className={cx(
              styles["first-section__card"],
              styles["first-section__yellow-card"]
            )}
          >
            <h1 className={styles["card__title"]}>
              {{ pt: "SERVIÇOS", en: "SERVICES" }[language]}
            </h1>
            <ul className={styles["first-section__card-list"]}>
              {isFetching ? (
                <Loader />
              ) : !data ? null : (
                data.data.data.node.children.elements
                  .filter(
                    (area) =>
                      area.displayName ===
                      { pt: "Serviços", en: "Services" }[language]
                  )
                  .map((filteredArea) =>
                    filteredArea.children.elements
                      .sort(
                        (a, b) =>
                          moment(a.created).valueOf() -
                          moment(b.created).valueOf()
                      )
                      .map((offer) => {
                        return (
                          <li key={offer.uuid}>
                            <Link to={`oferta/${offer.uuid}`}>
                              {offer.fields.title}
                            </Link>
                          </li>
                        );
                      })
                  )
              )}
            </ul>
          </div>
          <div
            className={cx(
              styles["first-section__card"],
              styles["first-section__blue-card"]
            )}
          >
            <h1 className={styles["card__title"]}>
              {{ pt: "SOLUÇÕES", en: "SOLUTIONS" }[language]}
            </h1>
            <ul className={styles["first-section__card-list"]}>
              {isFetching ? (
                <Loader />
              ) : !data ? null : (
                data.data.data.node.children.elements
                  .filter(
                    (area) =>
                      area.displayName ===
                      { pt: "Soluções", en: "Solutions" }[language]
                  )
                  .map((filteredArea) =>
                    filteredArea.children.elements
                      .sort(
                        (a, b) =>
                          moment(a.created).valueOf() -
                          moment(b.created).valueOf()
                      )
                      .map((offer) => {
                        return (
                          <li key={offer.uuid}>
                            <Link to={`oferta/${offer.uuid}`}>
                              {offer.fields.title}
                            </Link>
                          </li>
                        );
                      })
                  )
              )}
            </ul>
          </div>
          <div
            className={cx(
              styles["first-section__card"],
              styles["first-section__red-card"]
            )}
          >
            <h1 className={styles["card__title"]}>
              {{ pt: "SETORES", en: "SECTORS" }[language]}
            </h1>
            <ul className={styles["first-section__card-list"]}>
              {isFetching ? (
                <Loader />
              ) : !data ? null : (
                data.data.data.node.children.elements
                  .filter(
                    (area) =>
                      area.displayName ===
                      { pt: "Setores", en: "Sectors" }[language]
                  )
                  .map((filteredArea) =>
                    filteredArea.children.elements
                      .sort(
                        (a, b) =>
                          moment(a.created).valueOf() -
                          moment(b.created).valueOf()
                      )
                      .map((offer) => {
                        return (
                          <li key={offer.uuid}>
                            <Link to={`oferta/${offer.uuid}`}>
                              {offer.fields.title}
                            </Link>
                          </li>
                        );
                      })
                  )
              )}
            </ul>
          </div>
        </div>
      </section>
      {/* <section className={cx(styles["section"], styles["second-section"])}>
        <div className={styles["second-section__image"]}></div>
        <div className={styles["second-section__text"]}>
          <h2>{solutions.secondSection.title[language]}</h2>
          <h6>{solutions.secondSection.subTitle[language]}</h6>
          <p>{solutions.secondSection.text[language]}</p>
          <h6>
            <Link to="/sobre">
              {solutions.secondSection.redirectLinkText[language]}
              <img
                src={arrow}
                alt="arrow"
                className={styles["second-section__arrow-icon"]}
              />
            </Link>
          </h6>
        </div>
      </section> */}
      <section className={cx(styles["section"], styles["third-section"])}>
        <div className={styles["third-section__image"]}></div>
        <div className={styles["third-section__text"]}>
          <h2>{solutions.thirdSection.title[language]}</h2>
          <h5>{solutions.thirdSection.subTitle[language]}</h5>
          <p>{solutions.thirdSection.text[language]}</p>
          <img
            src={solutions.thirdSection.image[language]}
            alt="agile"
            className={styles["third-section__agile-image"]}
          />
        </div>
      </section>
      <section className={cx(styles["section"], styles["fourth-section"])}>
        <div className={styles["fourth-section__image"]}></div>
        <div className={styles["fourth-section__text"]}>
          <h1>{solutions.fourthSection.title[language]}</h1>
          <Button
            text={solutions.fourthSection.buttonText[language]}
            color="button--red"
            alignSelf={window.innerWidth < 575 ? "center" : "flex-start"}
            showArrow={true}
            onClick={handlePopup}
          />
        </div>
      </section>
    </div>
  );
}
