import { AxiosResponse } from "axios";
import { backOfficeAPI } from "../Api";
import { Contacts, SocialMedia } from "./Types";

export const ContactsApi = () => ({
  getContacts: async (
    lang: string
  ): Promise<AxiosResponse<Contacts.RootObject>> =>
    new Promise((resolve, reject) => {
      backOfficeAPI.axios
        .post("/graphql", {
          query: `
            {
  node(path: "/contactos") {
    children(filter: {schema: {is: contact}},  lang: "${lang}", version: published) {
      elements {
        ... on contact {
          uuid
          created
          fields {
            address
            email
            googleMaps
            island
            slug
            name
            phoneNumber
            category
          }
        }
      }
    }
  }
}`,
        })
        .then((response: AxiosResponse<Contacts.RootObject>) => {
          resolve(response);
        });
    }),
  getSocialMedia: async (): Promise<AxiosResponse<SocialMedia.RootObject>> =>
    new Promise((resolve, reject) => {
      backOfficeAPI.axios
        .post("/graphql", {
          query: `
         {
  node(uuid:"14bdec388ae64f52b75b64eb6b24225e") {
    ... on redesSociais {
      fields {
        facebook
        instagram
        linkedin
        youtube
      }
    }
  }
}
`,
        })
        .then((response: AxiosResponse<SocialMedia.RootObject>) => {
          resolve(response);
        });
    }),
});
