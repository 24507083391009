import styles from "./PartnerPage.module.css";
import cx from "classnames";
// Components
import Loader from "../../../Components/Loader/Loader";
import StackGrid from "react-stack-grid";
import { Link } from "react-router-dom";
import Button from "../../../Components/Button/Button";
// Context
// Hooks
import { useContext, useEffect } from "react";
import { useQuery } from "react-query";
// Pages
// Resources
import { BsArrowRight } from "react-icons/bs";
import { LanguageContext } from "../../../Context/LanguageContext";
import { ApiInterface, APP_BACK_OFFICE_URL } from "../../../resources/api/Api";
import { useParams } from "react-router";
import useColumnWidth from "../../../Hooks/useColumnWidth";

function PartnerPage() {
  const { id } = useParams<{ id: string }>();
  const { language } = useContext(LanguageContext);
  const columnWidth = useColumnWidth();
  const { data, isFetching } = useQuery(["partner", language], () =>
    ApiInterface().partner.getPartner(language, id)
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (isFetching) {
    return <Loader />;
  }

  if (!data) {
    return null;
  }

  return (
    <div className={styles["partner"]}>
      <div className={styles["partner__grid"]}>
        <img
          alt=""
          src={`${APP_BACK_OFFICE_URL}nodes/${data.data.data.node.uuid}/binary/image`}
          className={styles["partner__image"]}
        />
        <div className={styles["partner__title"]}>
          <h2>{data.data.data.node.fields.title}</h2>
        </div>
        <div className={styles["partner__content"]}>
          <div className={styles["partner__short-description"]}>
            <p>
              <dd
                dangerouslySetInnerHTML={{
                  __html: data.data.data.node.fields.introduction,
                }}
              />
            </p>
          </div>
          <div className={styles["partner__button-container"]}>
            <a
              href={`//${data.data.data.node.fields.link}`}
              target="_blank"
              rel="noreferrer"
            >
              <Button
                text={{ pt: `Ver Portal`, en: "See Portal" }[language]}
                color="button--red"
                width="20em"
                showArrow={true}
              />
            </a>
          </div>
        </div>
      </div>
      {data.data.data.node.fields.longDescription && (
        <div className={styles["partner__long-description"]}>
          <dd
            dangerouslySetInnerHTML={{
              __html: data.data.data.node.fields.longDescription,
            }}
          />
        </div>
      )}
      {data.data.data.node.fields.projects.length !== 0 && (
        <div className={styles["partner__projects"]}>
          <h2>
            {
              {
                pt: "Projetos Associados",
                en: "Associated Projects",
              }[language]
            }
          </h2>
          <StackGrid columnWidth={columnWidth} monitorImagesLoaded={true}>
            {data.data.data.node.fields.projects.map(
              (project, index: number) => {
                let year = new Date(project.fields.date).getFullYear();
                return (
                  <Link key={index} to={`/portfolio/${project.uuid}`}>
                    <div className={styles["project"]}>
                      <div className={styles["project__image-container"]}>
                        <img
                          className={styles["project__image"]}
                          src={`${APP_BACK_OFFICE_URL}nodes/${project.uuid}/binary/image`}
                          alt={project.fields.title}
                        />
                        <div className={styles["project__view-more"]}>
                          <h6>
                            {{ pt: "Ver Projeto", en: "See Project" }[language]}
                          </h6>
                          <BsArrowRight />
                        </div>
                      </div>
                      <div
                        className={cx(
                          styles["project__text"],
                          styles[project.fields.color]
                        )}
                      >
                        <h6 className={styles["project__year"]}>{year}</h6>
                        <h6 className={styles["project__name"]}>
                          {project.fields.title}
                        </h6>
                      </div>
                    </div>
                  </Link>
                );
              }
            )}
          </StackGrid>
        </div>
      )}
    </div>
  );
}

export default PartnerPage;
