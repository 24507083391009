import { AxiosResponse } from "axios";
import { backOfficeAPI } from "../Api";
import { About } from "./Types";

export const AboutApi = () => ({
  getAboutCards: async (
    lang: string
  ): Promise<AxiosResponse<About.RootObject>> =>
    new Promise((resolve, reject) => {
      backOfficeAPI.axios
        .post("/graphql", {
          query: `
            {
                node(path: "/sobre") {
                    children(lang: "${lang}") {
                        elements {
                            ... on aboutUsCard {
                                uuid
                                fields {
                                    text
                                    title
                                    alwaysAdding
                                    image {
                                      binaryUuid
                                    }
                                    index
                                }
                            }
                        }
                    }
                }
            }`,
        })
        .then((response: AxiosResponse<About.RootObject>) => {
          resolve(response);
        });
    }),
});
