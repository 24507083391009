import styles from "./Contacts.module.css";
import cx from "classnames";
//Components
import Button from "../../Components/Button/Button";
import ContactsPopupContent from "../../Components/Popup/ContactsPopupContent/ContactsPopupContent";
import Popup from "../../Components/Popup/Popup";
import Accordion from "../../Components/Accordion/Accordion";
import AccordionItem from "../../Components/Accordion/AccordionItem/AccordionItem";
import { Fragment } from "react";
// Context
import { LanguageContext } from "../../Context/LanguageContext";
// Hooks
import { useState, useEffect, useContext } from "react";
import { useQuery } from "react-query";
// Pages
// Resources
import { ACCORDION, ISLANDS } from "../../resources/typing/enumerates";
import facebook_icon from "../../resources/media/icons/social-media/facebook.svg";
import youtube_icon from "../../resources/media/icons/social-media/youtube.svg";
import linkedin_icon from "../../resources/media/icons/social-media/linkedin.svg";
import instagram_icon from "../../resources/media/icons/social-media/instagram.svg";
import ContactsAddress from "../../Components/Contacts/ContactsAddress";
import { contacts } from "./contactsData";
import { ApiInterface } from "../../resources/api/Api";
import Loader from "../../Components/Loader/Loader";
import useWindowsSize from "../../Hooks/useWindowsSize";
import DropDownMenu from "../../Components/DropdownMenu/DropdownMenu";
import moment from "moment";

export default function Contacts() {
  const { language } = useContext(LanguageContext);
  const windowsSize = useWindowsSize();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedOption, setselectedOption] = useState(0);
  const [active, setActive] = useState(0);
  const [contactOptions, setContactOptions] = useState(
    contacts.menuOptions[language]
  );
  const { data, isFetching, refetch } = useQuery(["contacts", language], () =>
    ApiInterface().contacts.getContacts(language)
  );
  const { data: data2, isFetching: isFetching2 } = useQuery(
    ["socialMedia"],
    () => ApiInterface().contacts.getSocialMedia()
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const checkSelectedOption = () => {
    if (isFetching) {
      return <Loader />;
    }

    if (!data) {
      return null;
    }

    let data2 = data.data.data.node.children.elements.sort(
      (a, b) =>
        moment(a.created).valueOf() -
        moment(b.created).valueOf()
    )

    switch (selectedOption) {
      case 0:
        return (
          <div className={styles["contacts__address-container"]}>
            {data2.map(
              (address, index: number) => {
                return (
                  address.fields.category == "office" && (
                    <ContactsAddress address={address} key={index} />
                  )
                );
              }
            )}
          </div>
        );
      case 1:
        return (
          <Accordion type={ACCORDION.CUSTOM} key={0} allCollapsed={null}>
            {Object.keys(ISLANDS).map((key, index: number) => {
              return (
                <AccordionItem
                  title={Object.values(ISLANDS)[index]}
                  titleSize={"var(--font-size-3)"}
                  key={index}
                  id={`content${index}`}
                  id2={`item${index}`}
                >
                  {data2
                    .filter((address) => address.fields.island === key)
                    .map((filteredAddress, index: number) => {
                      return (
                        filteredAddress.fields.category == "globaledashop" && (
                          <ContactsAddress
                            address={filteredAddress}
                            key={index}
                          />
                        )
                      );
                    })}
                </AccordionItem>
              );
            })}
          </Accordion>
        );
      case 2:
        return (
          <Accordion type={ACCORDION.CUSTOM} key={1} allCollapsed={null}>
            {Object.keys(ISLANDS).map((key, index: number) => {
              return (
                <AccordionItem
                  title={Object.values(ISLANDS)[index]}
                  titleSize={"var(--font-size-3)"}
                  key={index}
                  id={`content${index}`}
                  id2={`item${index}`}
                >
                  {data2
                    .filter((address) => address.fields.island === key)
                    .map((filteredAddress, index: number) => {
                      return (
                        filteredAddress.fields.category == "vodafoneshop" && (
                          <ContactsAddress
                            address={filteredAddress}
                            key={index}
                          />
                        )
                      );
                    })}
                </AccordionItem>
              );
            })}
          </Accordion>
        );
    }
  };

  useEffect(() => {
    Object.keys(ISLANDS).map((_key, index: number) => {
      return (
        document.getElementById(`content${index}`)?.innerHTML === "" &&
        document
          .getElementById(`item${index}`)
          ?.classList.add(styles["is-empty"])
      );
    });
    setContactOptions(contacts.menuOptions[language]);
  }, [selectedOption, language]);

  const handlePopup = (): void => {
    setIsPopupOpen(!isPopupOpen);
  };

  return (
    <Fragment>
      {isPopupOpen && (
        <Popup closePopup={handlePopup}>
          <ContactsPopupContent closePopup={handlePopup} />
        </Popup>
      )}
      <div className={styles["contacts"]}>
        <div className={styles["contacts__map"]}></div>
        <div className={styles["contacts__info"]}>
          <div className={styles["contacts__general-info"]}>
            <h1 className={styles["contacts__title"]}>
              {contacts.title[language]}
            </h1>
            <div className={styles["contacts__row"]}>
              {isFetching2 ? (
                <Loader />
              ) : !data2 ? null : (
                <div className={styles["contacts__social-group"]}>
                  {data2.data.data.node.fields.facebook && (
                    <a
                      href={`//${data2.data.data.node.fields.facebook}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        alt="facebook-icon"
                        src={facebook_icon}
                        className={styles["contacts__social-icon"]}
                      />
                    </a>
                  )}
                  {data2.data.data.node.fields.linkedin && (
                    <a
                      href={`//${data2.data.data.node.fields.linkedin}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        alt="linkedin-icon"
                        src={linkedin_icon}
                        className={styles["contacts__social-icon"]}
                      />
                    </a>
                  )}
                  {data2.data.data.node.fields.instagram && (
                    <a
                      href={`//${data2.data.data.node.fields.instagram}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        alt="instagram-icon"
                        src={instagram_icon}
                        className={styles["contacts__social-icon"]}
                      />
                    </a>
                  )}
                  {data2.data.data.node.fields.youtube && (
                    <a
                      href={`//${data2.data.data.node.fields.youtube}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        alt="youtube-icon"
                        src={youtube_icon}
                        className={styles["contacts__social-icon"]}
                      />
                    </a>
                  )}
                </div>
              )}
              <Button
                text={contacts.buttonText[language]}
                color="button--red"
                showArrow={false}
                onClick={handlePopup}
              />
            </div>
          </div>
          <ul className={styles["contacts__options"]}>
            {windowsSize.width > 900 ? (
              contactOptions.map((option, index) => {
                return (
                  <li key={index} onClick={() => setActive(index)}>
                    <button
                      className={cx(
                        styles["contacts__options-btn"],
                        active === index &&
                          styles["contacts__options-btn--selected"]
                      )}
                      onClick={() => setselectedOption(index)}
                    >
                      {option}
                    </button>
                  </li>
                );
              })
            ) : (
              <div className={styles["contacts__dropdown_div"]}>
                <DropDownMenu 
                  className={styles["contacts__dropdown"]}
                  options={contactOptions}
                  onClick={setselectedOption}
                />
              </div>
            )}
          </ul>
          {checkSelectedOption()}
        </div>
      </div>
    </Fragment>
  );
}
