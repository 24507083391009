import styles from "./Documents.module.css";
// Components
import HorizontalMenu from "../../../Components/HorizontalMenu/HorizontalMenu";
import DropDownMenu from "../../../Components/DropdownMenu/DropdownMenu";
import InstitutionalDocuments from "../../../Components/InstitutionalDocuments/InstitutionalDocuments";
import PrivacyStatement from "../../../Components/PrivacyStatement/PrivacyStatement";
import Loader from "../../../Components/Loader/Loader";
// Context
// Hooks
import useWindowsSize from "../../../Hooks/useWindowsSize";
import { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
// Pages
// Resources
import { LanguageContext } from "../../../Context/LanguageContext";
import documents from "./documents.json";
import livro_reclamacoes from "../../../resources/media/icons/documents/livro_reclamacoes.png";
import { ApiInterface } from "../../../resources/api/Api";
import Certifications from "../../../Components/Certifications/Certifications";

function Documents() {
  const { language } = useContext(LanguageContext);
  const windowsSize = useWindowsSize();
  const { data, isFetching } = useQuery(["documents", language], () =>
    ApiInterface().documents.getDocuments(language)
  );
  const [selectedOption, setSelectedOption] = useState(0);

  function ComplaintBook() {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    return (
      <div className={styles["complaint-book"]}>
        <a
          href="https://www.livroreclamacoes.pt/inicio"
          target="_blank"
          rel="noreferrer"
        >
          {documents.complaintBook.text[language]}
          <img src={livro_reclamacoes} alt="livro de reclamações" />
        </a>
      </div>
    );
  }

  const returnSelectedOption = () => {
    switch (selectedOption) {
      case 0:
        return isFetching ? (
          <Loader />
        ) : !data ? null : (
          <InstitutionalDocuments
            institutionalDocuments={data.data.data.node.children.elements}
          />
        );
      case 1:
        return <PrivacyStatement />;
      case 2: 
      return isFetching ? (
        <Loader />
      ) : !data ? null : (
        <Certifications
          certifications={data.data.data.node.children.elements}
        />
      );
      case 3:
        return <ComplaintBook />;
    }
  };

  return (
    <div className={styles["documents"]}>
      {windowsSize.width > 900 ? (
        <HorizontalMenu
          options={documents.options[language]}
          onClick={setSelectedOption}
        />
      ) : (
        <DropDownMenu
          options={documents.options[language]}
          onClick={setSelectedOption}
        />
      )}
      <div className={styles["documents__content"]}>
        {returnSelectedOption()}
      </div>
    </div>
  );
}

export default Documents;
